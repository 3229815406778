<template>
  <div class="gpsr">
    <div
      class="gpsr-enter"
      @click="handleClick(true)"
    >
      <span 
        v-if="isClick"
        class="gpsr-enter__text"
      >
        {{ euRespTitle }}
      </span>
      <span 
        v-else
        class="gpsr-enter__text"
        v-html="euRespTitleHtml"
      >
      </span>
      <Icon
        v-if="isClick"
        name="sui_icon_more_right_12px_2"
        size="12px"
        color="#767676"
        :is-rotate="GB_cssRight"
      />
    </div>
    <s-drawer
      v-if="drawerCompReay"
      :visible="showDrawer"
      :show-close="true"
      direction="btt"
      max-size="90%"
      custom-class="gpsr-drawer"
      :append-to-body="true"
      @close-from-mask="handleClick(false)"
    >
      <div>
        <div class="gpsr-drawer__header">
          {{ euRespPersonDetail?.popUpTitle || language.SHEIN_KEY_PWA_34942 }}
          <i 
            class="iconfont icon-close gpsr-drawer__close" 
            @click="handleClick(false)"
          ></i> 
        </div>
        <div class="gpsr-drawer__content">
          <div class="gpsr-drawer__title">
            {{ euRespPersonDetail?.tipsTitle || language.SHEIN_KEY_PWA_34943 }}
          </div>
          <div class="gpsr-drawer__info">
            {{ euRespPersonDetail?.tipsText || language.SHEIN_KEY_PWA_34944 }}
          </div>
          <template v-for="item in euRespPersonDetail?.tipsContents || []">
            <div
              :key="item.key"
              class="gpsr-drawer__info"
            >
              {{ item.key }}: <span v-html="item.value"></span>
            </div>
          </template>
        </div>
      </div>
    </s-drawer>
  </div>
</template>

<script setup name="EuRespPerson">
import { defineProps, ref, computed } from 'vue'
import { template } from '@shein/common-function'
const { GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const props = defineProps({
  euRespPerson: {
    type: Object,
    default: () => {}
  },
  euRespPersonDetail: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => ({})
  },
  getEuRespPersonFn: {
    type: Function,
    default: () => ({})
  },
})

const euRespTitle = computed(() => props.euRespPerson?.euRespTitle || '')
const euRespTitleHtml = computed(() => template(`<a style='color:#5a29bb;text-decoration: none;' target='_blank' href='${props.euRespPerson?.specialClickUrl}'>${props.euRespPerson?.highLightText}</a>`, props.euRespPerson?.specialEuRespTitle))
const isClick = computed(() => props.euRespPerson?.isClick == '1' || false)

const drawerCompReay = ref(false)
const showDrawer = ref(false)
const handleClick = async (flag) => {
  if(!isClick.value) return
  if (!drawerCompReay.value) {
    drawerCompReay.value = true
    showDrawer.value = true
  }else{
    showDrawer.value = flag

  }
}
</script>

<style lang="less">
.gpsr{
  padding-bottom: 0.32rem;
}
.gpsr-enter{
  color: #767676;
  line-height: 0.42667rem;
  padding: 0.10667rem 0;
  display: flex;
  align-items: center;
  &__text{
    .line-camp(3);
    margin-right: 0.05333rem;
  }
}
.gpsr-drawer{
  border-radius: 0.21333rem 0.21333rem 0rem 0rem;
  &__close {
    position: absolute;
    font-size: 0.3467rem;
    .right(0.48rem);
    top: 0.0533rem;
  }
  &__header{
    height: 1.06667rem;
    line-height: 1.06667rem;
    text-align: center;
    font-size: 16px;
    font-weight: 590;
    color: #000;
  }
  &__content{
    padding: 0.21333rem 0.32rem;
  }
  &__title{
    font-size: 14px;
    font-weight: 590;
    color: #000;
    margin-bottom: 0.32rem;
  }
  &__info{
    margin-bottom: 0.32rem;
    font-size: 12px;
    color: #666666;
    line-height: 14px;
  }
}
</style>
