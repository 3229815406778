<template>
  <s-dialog
    :visible="info.show"
    class="c-bind-email"
    :append-to-body="true"
    :show-close="true"
    @close-from-icon="handleClose('close')"
  >
    <!-- 绑定邮箱 -->
    <div
      v-if="type === 'bind'"
      class="bind-email"
    >
      <p class="title">
        {{ langText.SHEIN_KEY_PWA_16390 }}
      </p>
      <input
        v-model="bindEmail"
        type="text"
        :placeholder="langText.SHEIN_KEY_PWA_14945"
        @blur="handleBlurBindEmail"
        @input="handleInputBindEmail"
      />
      <div
        v-show="bindEmailTip.show"
        class="text-error"
      >
        {{ bindEmailTip.txt }}
      </div>
      <SButton
        :type="['primary', 'H72PX']"
        :width="'100%'"
        class="third-bind__btn"
        :disabled="isDisableBindEmailBtn"
        @click="handleBindEmail"
      >
        {{ langText.SHEIN_KEY_PWA_16389 }}
      </SButton>
    </div>
    <!-- 社交绑定社交方式 -->
    <div
      v-if="type === 'third'"
      class="bind-thrid-platform"
    >
      <p class="title">
        {{ info && info.source === 'Normal' ? langText.SHEIN_KEY_PWA_16394 : langText.SHEIN_KEY_PWA_16720 }}
      </p>
      <ul class="outer-link">
        <li
          v-if="info.repeatType.includes(loginType['Google'])"
          class="google-login"
          @click="chooseThirdType('Google')"
        >
          <span
            href="javascript:;"
            class="iconfont icon-Google1 j-google-login"
          >
            <i><img :src="`${PUBLIC_CDN}/pwa_dist/images/login/Google-Icon-a3887f014d.svg`" /></i>
          </span>
          <em class="name">Google</em>
          <s-radio
            v-model="bindThirdType"
            class="third-bind__radio"
            label="Google"
          />
        </li>
        <li
          v-if="info.repeatType.includes(loginType['Facebook'])"
          @click="chooseThirdType('Facebook')"
        >
          <span
            href="javascript:;"
            class="iconfont icon-Facebook"
          ></span>
          <em class="name">Facebook</em>
          <s-radio
            v-model="bindThirdType"
            class="third-bind__radio"
            label="Facebook"
          />
        </li>
        <li
          v-if="info.repeatType.includes(loginType['VK'])"
          @click="chooseThirdType('VK')"
        >
          <span
            href="javascript:;"
            class="iconfont icon-vk1"
          ></span>
          <em class="name">VK</em>
          <s-radio
            v-model="bindThirdType"
            class="third-bind__radio"
            label="VK"
          />
        </li>
        <li
          v-if="isShowKakaobindThird"
          @click="chooseThirdType('Kakao')"
        >
          <span class="outer-link__icon">
            <KakaoIcon />
          </span>
          <em class="name">Kakao</em>
          <s-radio
            v-model="bindThirdType"
            class="third-bind__radio"
            label="Kakao"
          />
        </li>
        <li
          v-if="isShowNaverbindThird"
          @click="chooseThirdType('Naver')"
        >
          <span class="outer-link__icon">
            <NaverIcon />
          </span>
          <em class="name">Naver</em>
          <s-radio
            v-model="bindThirdType"
            class="third-bind__radio"
            label="Naver"
          />
        </li>
      </ul>
      <SButton
        :type="['primary', 'H72PX']"
        :width="'100%'"
        class="third-bind__btn"
        :disabled="isDisableButton"
        @click="handleThirdBindConfirm"
      >
        {{ langText.SHEIN_KEY_PWA_15783 }}
      </SButton>
      <p
        v-if="info.source === 'Normal'"
        class="change-email"
        @click="toReigister"
      >
        {{ langText.SHEIN_KEY_PWA_16395 }}
      </p>
      <p
        v-else
        class="change-email"
        @click="toBind"
      >
        {{ langText.SHEIN_KEY_PWA_16392 }}
      </p>
    </div>
    <!-- 登录绑定 -->
    <div
      v-if="type === 'loginBind'"
      class="bind-login"
    >
      <p class="title">
        {{ langText.SHEIN_KEY_PWA_16391 }}
      </p>
      <div class="input-con input-email">
        <input
          v-model="bindEmail"
          type="text"
          :placeholder="langText.SHEIN_KEY_PWA_14945"
          disabled
        />
      </div>
      <div class="input-con input-email">
        <input
          v-model="bindPassword"
          :type="isShowPassword ? 'text' : 'password'"
          :placeholder="langText.SHEIN_KEY_PWA_15598"
          @input="handleInputLoginPassowrd"
        />
        <i
          class="iconfont"
          :class="{
            'icon-see-pass': isShowPassword,
            'icon-pass': !isShowPassword,
            'icon-ar-position': GB_cssRight
          }"
          style="color: #999999"
          @click="handleEyeBtn"
        ></i>
        <div
          v-show="bindPasswordTip.show"
          class="text-error"
        >
          {{ bindPasswordTip.txt }}
        </div>
      </div>
      <div
        v-show="isShowGoogleRecaptcha"
        ref="bindRecaptchaVnode"
        class="j-bind-grecaptcha"
        style="margin-top: 15px"
      ></div>
      <SButton
        :type="['primary', 'H72PX']"
        :width="'100%'"
        class="third-bind__btn"
        :disabled="isDisableLoginBindBtn"
        @click="handleLoginBind"
      >
        {{ langText.SHEIN_KEY_PWA_16389 }}
      </SButton>
      <p
        class="change-email"
        @click="toForget"
      >
        {{ langText.SHEIN_KEY_PWA_15582 }}
      </p>
      <!-- <p class="change-email" v-else @click="toReigister">{{langText.SHEIN_KEY_PWA_16395}}</p> -->
    </div>
    <!-- 成功 -->
    <div
      v-if="type === 'success'"
      class="bind-success"
    >
      <span class="iconfont icon-verifty-suc"></span>
      <p>{{ successTip }}</p>
      <SButton
        :type="['primary', 'H72PX']"
        :width="'100%'"
        class="third-bind__btn"
        @click="handleSuccessBtn"
      >
        {{ langText.SHEIN_KEY_PWA_15783 }}
      </SButton>
    </div>
    <!-- 失败 -->
    <div
      v-if="type === 'failure'"
      class="bind-failure"
    >
      <span class="iconfont icon-confirm_error"></span>
      <p>{{ failureTip }}</p>
      <p>{{ failureTip2 }}</p>
      <SButton
        :type="['primary', 'H72PX']"
        :width="'100%'"
        class="third-bind__btn"
        @click="handleFailedBtn"
      >
        {{ langText.SHEIN_KEY_PWA_15783 }}
      </SButton>
      <p
        class="change-email"
        @click="toBind"
      >
        {{ langText.SHEIN_KEY_PWA_16392 }} >
      </p>
    </div>
    <div slot="footer"></div>
  </s-dialog>
</template>

<script>
/* globals __GB_LoginModalInfo__, grecaptcha, signupTimeTag */
import {
  checkEmail, 
  checkPw, 
  urlRedirection, 
  formatErrorLoginInput, 
  formatErrorLoginInputGa, 
  formatNormalErrorMessage,
  newPrivacyCache
} from '../../util'
import { loginBind, loginThirdSer, loginSer } from '../../service'
import { callFaceBookLogin, callGoogleLogin, callVKLogin, callKakaoLogin, callNaverLogin } from 'public/src/pages/login/common/thirdLoginMethods.js'
import { mapMutations, mapState, mapActions } from 'vuex'
import { htmlDecode } from '@shein/common-function'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { riskVerifyChallenge } from '@login/common/riskVerifyManager.js'
import { exposeRegistrationSuccessPop } from '@login/utils/loginAnalysis'
import KakaoIcon from 'public/src/pages/login/components/Icon/KakaoIcon.vue'
import NaverIcon from 'public/src/pages/login/components/Icon/NaverIcon.vue'

const { lang, PUBLIC_CDN, langPath, host, isUserMediumSite, GB_cssRight } = typeof gbCommonInfo === 'object' ? gbCommonInfo : {}
const { isSupportGoogleLogin, GOOGLE_VERIFY_SITEKEY } = typeof __GB_LoginModalInfo__ === 'object' ? __GB_LoginModalInfo__ : {}
// 登录方式对应的数值
const LoginType = {
  Normal: 7, // 普通邮箱登录方式
  Google: 12,
  Facebook: 11,
  VK: 13,
  Line: 15,
  Kakao: 16,
  Naver: 17
}

export default {
  name: 'ThirdEmailBind',
  components: {
    KakaoIcon,
    NaverIcon
  },
  props: {
    langText: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    },
    // bind:绑定邮箱; third: 社交账号冲突; success: 成功; failure: 绑定失败; loginBind: 登录绑定
    type: {
      type: String,
      default: ''
    },
    emailP: {
      type: String,
      default: ''
    },
    pageFrom: {
      type: String,
      default: ''
    },
    newPrivacy: { type: Object, default: () => ({}) }
  },
  data: () => ({
    GB_cssRight,
    lang,
    isSupportGoogleLogin,
    PUBLIC_CDN,
    loginType: LoginType,
    isDisableButton: false,
    bindEmail: '', // 需要绑定的邮箱
    bindEmailTip: { show: false, txt: '' },
    // 绑定社交方式
    bindThirdType: '',
    successTip: '',
    failureTip: '',
    failureTip2: '',
    isShowPassword: false,
    bindPassword: '',
    bindPasswordTip: { show: false, txt: '' },
    isDisableBindEmailBtn: true,
    isDisableLoginBindBtn: true,
    isShowGoogleRecaptcha: false,
    bindGoogleRecaptcha: { id: '', token: '' },
    userResult: {},
    isNeedValidateEmail: false,
    riskInfo: {},
    loginRiskRetryTimes: 0,

  }),
  computed: {
    ...mapState('login', ['defaultLocation', 'commonAbt', 'isNewRegisterPop']),
    isShowKakaobindThird () {
      return this.info.repeatType.includes(this.loginType['Kakao']) && (this.commonAbt?.kakaoLogin == 'yes')
    },
    isShowNaverbindThird () {
      return this.info.repeatType.includes(this.loginType['Naver']) && (this.commonAbt?.naverLogin == 'yes')
    },
  },
  watch: {
    emailP: {
      handler: function (v) {
        this.bindEmail = v
      },
      immediate: true
    },
    type: {
      handler: function(v){
        this.$nextTick(() => {
        // 直接由三方登录注册成功
          if (v === 'success' && this.info.isDirectSuccess) {
            let txt = this.langText.SHEIN_KEY_PWA_16397
            txt = txt.replace(/\{0\}/, this.info.source) // 注册方式
            txt = txt.replace(/\{1\}/, this.info.email) // 注册的邮箱
            this.successTip = txt
            exposeRegistrationSuccessPop('normal_other')
          } else if (v === 'failure' && this.info.isDirectFailure) {
            let txt = this.langText.SHEIN_KEY_PWA_16393
            txt = txt.replace(/\{\d\}/, this.info.source) // 注册方式
            this.failureTip = txt
            this.failureTip2 = this.langText.SHEIN_KEY_PWA_16399
          }
          isUserMediumSite && this.$evt.$emit('show-bind-email-modal', this.info)
        })
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('login', ['setNewPrivacyModal', 'assignState', 'changeDialogs']),
    ...mapActions('login', ['setRegisterPopData']),
    handleBlurBindEmail () {
      if (checkEmail(this.bindEmail, this.bindEmailTip)) {
        this.isDisableBindEmailBtn = false
      }
    },
    handleInputBindEmail () {
      if (checkEmail(this.bindEmail, {})) {
        this.isDisableBindEmailBtn = false
      }
    },
    handleClose (from) {
      this.bindThirdType = ''
      if (this.type === 'success') {
        const userData = Object.keys(this.userResult).length ? this.userResult : this.info.user
        urlRedirection(false, userData)
      } 
      this.$emit('close', from)
    },
    handleFailedBtn () {
      this.$evt.$emit('click-bind-failed-confirm', { info: this.info })
      this.handleClose()
    },
    handleSuccessBtn () {
      this.$evt.$emit('click-bind-success-confirm', { info: this.info })
      const userData = Object.keys(this.userResult).length ? this.userResult : this.info.user
      urlRedirection(false, userData)
      this.$emit('update-bind-info', { show: false })
    },
    handleBindEmail () {
      const email = this.bindEmail.trim()
      if (!checkEmail(email, this.bindEmailTip)) {
        isUserMediumSite && this.$evt.$emit('click-bind-email', {
          info: this.info,
          msg: formatErrorLoginInputGa(email, '', true),
          biMsg: formatErrorLoginInput(email, '', true)
        })
        return
      }
      const { accessToken, socialId, source } = this.info
      this.isDisableButton = true
      this.$evt.$emit('event-loading', true)
      this.requestLoginThird(source, { email, socialId, accessToken, email_source: 0, third_email: 0 })
    },
    handleEyeBtn () {
      this.isShowPassword = !this.isShowPassword
    },
    toBind () {
      if (this.isDisableButton) return
      this.bindThirdType = ''
      if (this.info.source === 'Normal') {
        this.$evt.$emit('click-to-register', { info: this.info })
        this.$emit('close')
      }
      this.$evt.$emit('click-to-bind', { info: this.info })
      this.$emit('update-bind-info', { type: 'bind', originType: 'bind' })
    },
    toReigister () {
      if (this.isDisableButton) return
      this.$evt.$emit('click-to-register', { info: this.info })
      this.$emit('close')
    },
    toForget () {
      this.$emit('update-bind-info', { show: false })
      this.$evt.$emit('click-to-forget', { info: this.info })
      this.$emit('show-forget-password', { email: this.bindEmail, from: 'bind' })
    },
    handleInputLoginPassowrd () {
      const password = this.bindPassword
      if (checkPw(password, {})) {
        this.isDisableLoginBindBtn = false
      }
    },
    resetGoogleRecaptcha () {
      const { id } = this.bindGoogleRecaptcha
      if (id === '') {
        this.bindGoogleRecaptcha.id = grecaptcha.render(this.$refs.bindRecaptchaVnode, {
          sitekey: GOOGLE_VERIFY_SITEKEY,
          size: 'compact',
          callback: (res) => {
            this.isDisableLoginBindBtn = false
            this.bindGoogleRecaptcha.token = res
          },
          'expired-callback': () => {
            this.isDisableLoginBindBtn = true
          },
          'error-callback': () => {
            this.isDisableLoginBindBtn = true
          }
        })
        this.fixIosSafariRecaptcha()
        return
      }
      grecaptcha.reset(id)
      this.fixIosSafariRecaptcha()
    },
    fixIosSafariRecaptcha (times = 0) {
      window.__fixIosSafariRecaptchaTimer = setTimeout(() => {
        if (window.__fixIosSafariRecaptchaTimer) clearTimeout(window.__fixIosSafariRecaptchaTimer)
        if (times > 15) return
        const $iframe = $('iframe[src*="recaptcha/api2/bframe"]')
        if ($iframe.length > 0) {
          $iframe
            .parent()
            .parent()
            .css('transform', 'translateZ(100000px)')
            .css('z-index', 100000)
        } else {
          this.fixIosSafariRecaptcha(++times)
        }
      }, 500)
    },
    handleLoginBind () {
      const email = this.bindEmail.trim()
      const password = this.bindPassword
      if (!checkEmail(email, this.bindEmailTip) || !checkPw(password, this.bindPasswordTip)) {
        this.$evt.$emit('click-login-bind-confirm', {
          info: this.info,
          gmsg: formatErrorLoginInputGa(email, password),
          bmsg: formatErrorLoginInput(email, password)
        })
        return
      }

      const userData = {
        email,
        password,
        g_recaptcha_response: this.bindGoogleRecaptcha.token
      }
      userData.clause_flag = 0
      if (this.newPrivacy.type == 'yes') {
        userData.clause_flag = 1
        if (newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')) {
          userData.clause_agree = 1
        }
      }
      this.requestLogin(userData)
    },
    async requestLogin (userData) {
      this.$evt.$emit('event-loading', true)
      const { source, socialId, accessToken } = this.info
      this.isDisableLoginBindBtn = false
      // 极验验证
      const res = await geetestChallenge(loginSer, userData, 'login')
      if (!res) {
        this.$evt.$emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.bindPasswordTip.show = true
        this.bindPasswordTip.txt = this.langText.SHEIN_KEY_PWA_16274
        return
      }
      if (status == 'close') {
        this.$evt.$emit('event-loading', false)
        return
      }
      const response = apiResData || {}
      this.$evt.$emit('request-login-success', { res: response })
      if (response?.code == 0) {
        this.userResult = response
        this.requestLoginBind(userData.email, source, userData.password, socialId, accessToken, 'Normal')
        return
      }
      this.$evt.$emit('click-login-bind-confirm', {
        info: this.info,
        gmsg: response.msg,
        bmsg: response.msg
      })
      this.$evt.$emit('event-loading', false)
      // 初始化极验验证码
      if (response?.needVerify) { // 需要Google验证码
        this.isShowGoogleRecaptcha = true
        this.resetGoogleRecaptcha() // 重设人机验证的数据
        this.isDisableLoginBindBtn = true
      } else {
        this.isShowGoogleRecaptcha = false
        this.isDisableLoginBindBtn = false
      }
      if (response?.code == 400504) { // 密码错误
        this.bindPasswordTip.txt = htmlDecode({ text: this.langText.SHEIN_KEY_PWA_15588 })
        this.bindPasswordTip.show = true
        return
      }
      if(response?.code == 402922){
        riskVerifyChallenge(response, userData, ({ paramsData, type }) => {
          if(type == 'error') {
            this.$evt.$emit('event-loading', false)
            this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.$evt.$emit('event-loading', false)
            return
          }
          this.requestLogin(paramsData)
        })
        return
      }

      if (response?.code == 400593) {
        this.$evt.$emit('event-loading', false)
        this.setNewPrivacyModal({
          from: 'Email',
          account: userData.email,
          cb: () => {
            this.$evt.$emit('event-loading', true)
            userData.clause_agree = 1
            this.requestLogin(userData)
          }
        })
        return
      }
      if (response?.code == 402906) {
        this.riskInfo = { 
          risk_id: response?.info?.risk_id,
          email: userData?.email,
          cb: () => {
            Object.assign(userData, { uberctx_risk_uuid: response?.info?.risk_id })
            this.requestLogin(userData)
          }
        }
        this.changeDialogs({
          key: 'showEmailValidate',
          val: { show: true, riskInfo: this.riskInfo },
        })
        this.$evt.$emit('event-loading', false)
        return
      }
      if (response?.code == 1403 || response?.code == 1404) { // 失败验证
        this.$evt.$emit('event-loading', false)
        return
      }
      const errTip = formatNormalErrorMessage(response)
      this.bindPasswordTip.show = true
      this.bindPasswordTip.txt = errTip
    },
    /**
     * 绑定登录方式公用方法
     * @param email 需要绑定的邮箱
     * @param bindType 绑定类型，传英文(Google/Facebook/VK/Normal)
     * @param password 密码
     * @param socialId 第三方登录id
     * @param accessToken 第三方校验token
     * @param lastType 最终绑定的第三方类型传英文(Google/Facebook/VK/Normal), 主要用来成功提示里面替换字符
     */
    requestLoginBind (email, bindType, password, socialId, accessToken, lastType) {
      const options = { email, type: LoginType[bindType], password, social_id: socialId, access_token: accessToken }
      loginBind(options, (err, data) => {
        this.$evt.$emit('event-loading', false)
        if (err) console.log(err)
        if (data.code == 0) {
          // 绑定成功后需要展示修改密码
          if (data && data.info && data.info.result == 1 && this.userResult && this.userResult.info && this.userResult.info.member) {
            this.userResult.info.member.modifyPassword = 1
          }
          
          const { type } = this.info // 保存未更新之前的类型
          this.$evt.$emit('request-login-bind-success', { info: this.info })
          this.$emit('update-bind-info', { type: 'success' })
          if (type == 'loginBind') { // 邮箱&密码登录绑定方式
            this.successTip = this.langText.SHEIN_KEY_PWA_16396.replace(/\{\d\}/g, bindType)
            return
          }
          // 社交媒体跟社交媒体账号冲突
          if (/Google|Facebook|VK|Kaoka|Naver/.test(bindType) && /Google|Facebook|VK|Kaoka|Naver/.test(lastType)) {
            this.successTip = this.langText.SHEIN_KEY_PWA_17053.replace('{0}', bindType).replace('{1}', lastType)
            return
          }
          this.successTip = this.langText.SHEIN_KEY_PWA_17052.replace(/\{\d\}/g, lastType)
          return
        }
        // 不需要绑定
        // if (data.code == 400541) {
        //     urlRedirection()
        //     return
        // }
        // 能进入到绑定阶段，肯定是登录成功了
        // alert(data.msg)
        urlRedirection(false, this.userResult)
      })
    },
    chooseThirdType (type) {
      this.bindThirdType = type
    },
    // 三方账号绑定点击
    handleThirdBindConfirm () {
      const type = this.bindThirdType
      if (type === '') return
      this.$evt.$emit('click-third-platform-submit', { info: this.info, target: type })
      if (type === 'Facebook') {
        this.handleFacebook()
        return
      }
      if (type === 'Google') {
        this.handleGoogle()
        return
      }
      if (type === 'VK') {
        this.handleVk()
      }
      if (type === 'Kakao') {
        this.handleKakao()
      }
      if (type === 'Naver') {
        this.handleNaver()
      }
    },
    // 获取Facebook uid、token
    handleFacebook () {
      callFaceBookLogin((token, uid, email) => {
        this.requestLoginThird('Facebook', { email, socialId: uid, accessToken: token }, () => {
          const { email, password, source, socialId, accessToken } = this.info
          this.requestLoginBind(email, source, password, socialId, accessToken, 'Facebook')
        })
      })
    },
    handleGoogle () {
      this.$evt.$emit('event-loading', true)
      callGoogleLogin((data) => {
        const { code, id, email, id_token } = data || {}
        if (code == 0) {
          this.requestLoginThird('Google', { email, socialId: id, accessToken: id_token }, () => {
            const { email, password, source, socialId, accessToken } = this.info
            this.requestLoginBind(email, source, password, socialId, accessToken, 'Google')
          })
          return
        }
        this.$toast(this.langText.SHEIN_KEY_PWA_14899)
      })
    },
    handleVk () {
      const redirect_uri = `${host}${langPath}/vklogin/auth?s=bind`
      callVKLogin(redirect_uri, (data) => {
        const { user_id, email, access_token } = data
        this.$evt.$emit('event-loading', true)
        // this.requestLoginBind(bindEmail, LoginType['VK'], '', user_id, access_token)
        this.requestLoginThird('VK', { email: email, socialId: user_id, accessToken: access_token }, () => {
          const { email, password, source, socialId, accessToken } = this.info
          this.requestLoginBind(email, source, password, socialId, accessToken, 'VK')
        })
      })
    },
    handleKakao () {
      this.$evt.$emit('event-loading', true)
      callKakaoLogin(data => {
        const { code, profile, kakao_token } = data || {}
        if (code == 0) {
          this.requestLoginThird('Kakao', {
            email: profile?.kakao_account?.email || '',
            socialId: profile.id,
            accessToken: kakao_token
          }, () => {
            const { email, password, source, socialId, accessToken } = this.info
            this.requestLoginBind(email, source, password, socialId, accessToken, 'Kakao')
          })
          return
        }
        this.$toast(this.langText.SHEIN_KEY_PWA_14899)
      })
    },
    handleNaver () {
      this.$evt.$emit('event-loading', true)
      callNaverLogin(data => {
        const { code, profile, naver_token } = data || {}
        if (code == 0) {
          this.requestLoginThird('Naver', { email: profile?.email || '', socialId: profile.id, accessToken: naver_token }, () => {
            const { email, password, source, socialId, accessToken } = this.info
            this.requestLoginBind(email, source, password, socialId, accessToken, 'Naver')
          })
          return
        }
        this.$toast(this.langText.SHEIN_KEY_PWA_14899)
      })
    },
    async requestLoginThird (type, reqData, cb) {
      reqData.clause_flag = 0
      reqData.locationId = this.newPrivacy.origin == 'mix' ? ( this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
      if (this.newPrivacy.type == 'yes') {
        reqData.clause_flag = 1
        if (newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')) {
          reqData.clause_agree = 1
        }
      }
      loginThirdSer(LoginType[type], reqData).then(async (res) => {
        this.isDisableButton = false
        this.$evt.$emit('event-loading', false)
        this.$evt.$emit('request-login-third-result', { info: this.info, res, type })
        const { code, info } = res
        if (code == 0) { // 登录成功
          this.userResult = res
          clearInterval(signupTimeTag)
          if (typeof cb == 'function') return cb()
          if (info && info.member && info.member.isRegister && isUserMediumSite) {
            const registerSuccess =  this.commonAbt.registerSuccess
            if(registerSuccess == 'typeA') {
              await this.setRegisterPopData()
            }
            const isNewRegisterPop = this.isNewRegisterPop
            if(isNewRegisterPop) {
              this.$evt.$emit('event-loading', false)
              this.$emit('update-bind-info', { show: false })
              urlRedirection(false, res)
            } else {
              this.$emit('update-bind-info', {
                isDirectSuccess: true,
                type: 'success',
                email: reqData.email,
                source: type,
                user: res
              }) 
            }
          } else {
            urlRedirection(false, res)
          }
          return
        }
        this.$evt.$emit('event-loading', false)

        if(code == 402922){
          riskVerifyChallenge(res, reqData, ({ paramsData, type: challengeType }) => {
            if(challengeType == 'error') {
              this.$evt.$emit('event-loading', false)
              this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
              return
            }
            if(challengeType == 'close') {
              this.$evt.$emit('event-loading', false)
              return
            }
            this.requestLoginThird(type, paramsData)
          })
          return
        }
        if (code == 400503) { // 邮箱已注册
          this.bindEmailTip.txt = this.langText.SHEIN_KEY_PWA_15590
          this.bindEmailTip.show = true
          return
        }
        // 重复渠道, 拦截
        if (res.code == 400542) {
          this.$emit('update-bind-info', {
            type: 'failure',
            source: this.info.source === 'Normal' ? 'Normal' : type,
            isDirectFailure: true
          })
          return
        }
        if (res.code == 400540) { // 社交媒体账号重复
          if (this.pageFrom === 'act') { // 专题直接提示重复
            this.$emit('act-account-repeat')
            return
          }
          const repeatType = res.info.type
          const { accessToken, socialId, email } = reqData
          return this.$emit('update-bind-info', {
            accessToken,
            socialId,
            email,
            repeatType,
            // 7 为普通邮箱账号
            type: repeatType.includes(7) ? 'loginBind' : 'third',
            source: type,
            originType: repeatType.includes(7) ? 'loginBind' : 'third'
          })
        }
        if (res.code == 400579) { // 账号冻结中
          this.$toast(this.langText.SHEIN_KEY_PWA_16940)
          return
        }
        if (code == 400593) {
          this.$evt.$emit('event-loading', false)
          this.setNewPrivacyModal({
            from: type,
            cb: () => {
              this.$evt.$emit('event-loading', true)
              reqData.clause_agree = 1
              this.requestLoginThird(type, reqData, cb)
            }
          })
          return
        }
        alert('Please try later')
      })
    },

  },
}
</script>


<style lang="less">
/* stylelint-disable selector-max-specificity,selector-max-type,selector-class-pattern  */
.c-bind-email {
  .third-bind__radio{
  /* stylelint-disable-next-line */
    margin-right: 0 !important;
  }
  .third-bind__btn{
    margin-top: 25px;
  }
  .c-modal .modal-content {
    width: 7.6rem;
  }
  .icon-close {
    .font-dpr(15px);
    .fr();
    color: #999;
  }
  
  .text-error{
      padding-top: 2px;
      color: @sui_color_unusual;
      .txt-l();
      .font-dpr(24px);
  }
  .mshe-btn-black {
    margin-top: 22px;
    height: 32px;
    line-height: 32px;
    .font-dpr(28px);
    word-break: break-all;
    white-space: normal;
    line-height: 1.1;
  }
  .title {
      font-weight: bold;
  }
  .change-email {
      color: #093C6E;
      padding-top: 12px;
      .font-dpr(20px);
  }
  .bind-email {
    text-align: center;
    p {
      .font-dpr(28px);
      color: #222;
      padding: 10px 0;
    }
    input::-webkit-input-placeholder {
      color: #ccc;
    }
    input {
      width: 100%;
      height: 32px;
      line-height: 32px;
      background: #F6F6F6;
      border: none;
      padding: 0 6px;
      color: #222;
    }
  }
  .bind-thrid-platform {
      padding-top: 10px;
      text-align: center;
      .outer-link {
          margin-top: .4rem;
          align-items: flex-start;
          > li {
          .flexbox();
          align-items: center;
          justify-content: space-between;
          span {
              .font-dpr(48px);
              margin: 0 12px 0 0;
              color: #3949AB,
          }
          .icon-vk1 {
              color: #4680C2;
          }
          .name {
              flex: 1 1 auto;
              .txt-l();
          }
          img {
              display: block;
              margin: 0 auto;
              .box-width-height-dpr(width, 36px);
              .box-width-height-dpr(height, 36px);
          }
          }
          .google-login {
          .box-width-height-dpr(height, 72px);
          [class*="iconfont"] i {
              display: flex;
              align-items: center;
              .box-width-height-dpr(width, 52px);
              .box-width-height-dpr(height, 52px);
              .border-dpr(border, 2px, #e5e5e5);
              border-radius: 50%;
          }
          }
        &__icon {
          height: .7733rem;
        }
        &__icon svg {
          width: .64rem;
          height: .64rem;
        }
      }
  }
  .bind-login {
    text-align: center;
    .input-con {
      position: relative;
      margin-top: 12px;
      input {
        background: #F6F6F6;
        border: 0;
        height: 32px;
        line-height: 32px;
        padding: 0 6px;
        color: #999;
        width: 100%;
      }
      input::-webkit-input-placeholder {
        color: #ccc;
      }
      [class*="iconfont"] {
        position: absolute;
        right: 6px;
        top: 17px;
        transform: translateY(-50%);
        .font-dpr(40px);
        &.icon-ar-position {
          left: 6px;
          right: initial;
        }
      }
    }
  }
  .bind-success {
    text-align: center;
    [class*="iconfont"] {
      .font-dpr(140px);
      color: @color_green;
    }
    p {
      color: #222;
      word-break: break-all;
    }
  }
  .bind-failure {
    text-align: center;
    [class*="iconfont"] {
      .font-dpr(140px);
      color: #222;
    }
    p {
      color: #222;
    }
    .change-email {
      color: #093C6E;
    }
  }
}
</style>
