<script lang="jsx">
import { mapGetters, mapState, mapActions } from 'vuex'
import DescriptionEnter from './components/DescriptionEnter'
import EuRespPerson from './components/EuRespPerson'
import UserManualEnter from './components/UserManualEnter'
import DescriptionDrawer from './components/DescriptionDrawer' // TODO @Levi Su 没必要同步
import UserManualDrawer from './components/UserManualDrawer'
import Material from 'public/src/pages/goods_detail/middlect/components/Material'
import DetailFPos from 'public/src/pages/goods_detail/middlect/components/DetailFPos'
import DetailOPos from 'public/src/pages/goods_detail/middlect/components/DetailOPos'
import ModelIntro from 'public/src/pages/goods_detail/middlect/components/ModelIntro'
import SizeGuideEnter from 'public/src/pages/goods_detail/middlect/components/SizeGuideEnter'
import IngredientsEnter from 'public/src/pages/goods_detail/middlect/components/IngredientsEnter'
import BrandSeriesEnter from 'public/src/pages/goods_detail/middlect/components/DetailSignBoard/components/BrandSeriesEnter'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-6-4' })

export default {
  name: 'MiddleAttr',
  components: {
    DetailFPos,
    DetailOPos,
    ModelIntro,
    SizeGuideEnter,
    IngredientsEnter,
    NewIngredientsEnter: () => import('public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/IngredientsEnter/index.vue'),
    Material,
    DescriptionEnter,
    EuRespPerson,
    UserManualEnter,
    DescriptionDrawer,
    BrandSeriesEnter,
    UserManualDrawer
  },
  computed: {
    ...mapState('newProductDetail/common', ['currentLocalCountry']), // 新数据结构
    ...mapState('newProductDetail', ['descriptionReady', 'userManualReady']),
    ...mapState('newProductDetail/common', ['descriptionStatus', 'currentLocalUnit', 'detail', 'userManualStatus']),
    ...mapGetters('newProductDetail', ['fsAbt']), // 新数据流
    ...mapGetters('newProductDetail/SizeGuide', ['localsize']),
    ...mapGetters('newProductDetail/common', [ 'parentCats', 'moduleRenderConfig', 'showStoreAndBrand', 'showAuthenticbrand', 'isBrandHasTargetTag', 'detailFPosContent', 'isStoreBusinessBrand', 'brandMapInfo', 'language', 'detail', 'sellingPoints', 'showSizeGuide', 'inFashionStoreImg', 'trendsInfo']),
    ...mapGetters('newProductDetail/MiddleAttr', ['model', 'pageComponents', 'sizeInfoDes', 'showIngredientEnter', 'showModelIntro', 'newDetailsArr', 'orginAttrArray', 'detailsArr', 'multiDescription', 'descriptionList', 'drawerContent', 'showEvoluEntry', 'cccDescAttrList']),
    // BFF新vuex
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapGetters('productDetail/MiddleAttr', ['ingredient_text']),
    ...mapGetters('productDetail/EuRespPerson', [
      'eu_resp_person',
      'eu_resp_persopn_detail'
    ]),
    // 是否展示普通品牌模块（description楼层上面）
    isShowStoreAndBrand() {
      /**
       * Authenticbrand=none
        新标签（标签id：600012606）的商品不展示品牌模块和“100%正品”文案
        原品牌集成店的商品展示品牌模块，老样式，且在原来的位置（移动端是descrption上方，pc是物流退换货下方）
       */
      // const isShowNewStoreBrand = this.brandMapInfo && this.showAuthenticbrand && (this.isBrandHasTargetTag || this.isStoreBusinessBrand)
      // 如果展示在物流模块上方 就不展示这个普通品牌小模块了
      // if(isShowNewStoreBrand) return false
      return this.showStoreAndBrand
    },
    showEvolushein() {
      return this.fsAbt?.evolushein?.p?.evolushein === 'show'
    },
    showUserManual() {
      return this.fsAbt?.usermanual?.param?.usermanual === 'show' && this.detail.instruction_url
    },
    sizeGuidPlanA(){
      return this.fsAbt?.sizeguideyouhua520?.param?.sizeguideyouhua520 === 'planA'
    },
    unitType() {
      const originUnit = this.sizeInfoDes?.sizeUnit != '1' ? 'cm' : 'inch'
      return this.currentLocalUnit || originUnit
    }
  },
  watch: {
    'descriptionStatus' (newVal) {
      if (newVal) {
        // 卖点曝光埋点
        if (this.sellingPoints?.length) {
          daEventCenter.triggerNotice({
            daId: '1-6-2-35',
            extraData: {
              sellingPoints: this.sellingPoints
            }
          })
        }
      }
    },
    'userManualStatus' (newVal) {
      if(newVal){
        daEventCenter.triggerNotice({
          daId: '1-6-1-219',
        })
      }
    },
    // sellingPoints: {
    //   handler(newVal) {
    //     this.saveSellingPoints(newVal)
    //   },
    //   immediate: true
    // }
  },
  methods: {
    // ...mapMutations('productDetail', ['saveSellingPoints']),
    ...mapActions('newProductDetail', ['openDescriptionPop', 'closeDescriptionPop', 'openUserManualPop', 'closeUserManualPop']),
    ...mapActions('productDetail/EuRespPerson', [
      'get_eu_resp_persopn',
    ]),
  },
  render() {
    const { materialConf, descPosConf, sizeGuideConf } = this.pageComponents || {}
    const { fPosData, oPosData = [] } = descPosConf || {}
    const showMaterial = materialConf?.isShow && fPosData?.isShow && materialConf?.materialList?.length
    const showDetailPos = fPosData?.isShow && (this.detailFPosContent?.textAttr?.length || this.detailFPosContent?.imgAttr?.length || oPosData?.length)  // 过滤cv属性存在没有值的情况
    const showModule = fPosData?.showModule == 1 && (showMaterial || showDetailPos)
    if(typeof window !== 'undefined' && window.SaPageInfo && window.SaPageInfo.page_param) {
      window.SaPageInfo.page_param.is_include_cv = this.orginAttrArray.hasCVAttr ? 1 : 0  // 商详页埋点设置page_name
    }
    const renderIngredientEnter = () => {
      if (this.MAIN_BFF_APOLLO?.v1) {
        return this.ingredient_text ? 
          <NewIngredientsEnter 
            language={this.language} 
            ingredientText={this.ingredient_text}  
          /> : null
      }
      if (this.showIngredientEnter) {
        return <IngredientsEnter />
      }
      return null
    }
    return <div>
      <div>
        {showModule ?
          <div class="goods-attr__title">
            Product Details
          </div>
          : null}
        {this.showUserManual && showModule ?
          <UserManualEnter 
            fsAbt={this.fsAbt}
            language={this.language}
            openUserManualPopFn={this.openUserManualPop}
            box-style={{
              'border-bottom': showModule || this.isShowStoreAndBrand  ? '1px solid #e5e5e5' : 'none'
            }}
          /> : null
        }
        {showMaterial ? <Material /> : null}
        {showDetailPos ? <DetailFPos /> : null}
        {showDetailPos ? <DetailOPos /> : null}
      </div>
      {!showModule && this.showUserManual ? <UserManualEnter 
        fsAbt={this.fsAbt}
        language={this.language}
        openUserManualPopFn={this.openUserManualPop}
      /> : null}
      {this.isShowStoreAndBrand ?
        <BrandSeriesEnter
          data={this.brandMapInfo}
          is-store-brand={true}
          hide-jump-text={true}
        />
        : null}
      <DescriptionEnter
        details-arr={this.detailsArr}
        new-details-arr={this.newDetailsArr}
        language={this.language}
        pageComponents={this.pageComponents}
        fsAbt={this.fsAbt}
        sellingPoints={this.sellingPoints}
        openDescriptionPopFn={this.openDescriptionPop}
        cccDescAttrList={this.cccDescAttrList}
        box-style={{
          'border-top': showModule || this.isShowStoreAndBrand || this.showUserManual ? '1px solid #e5e5e5' : 'none'
        }}
        trendsImg={this.inFashionStoreImg}
        trendsInfo={this.trendsInfo}
        goodsId={this.detail.goods_id}
      />
      {this.eu_resp_person?.showGPSRTips ? 
        <EuRespPerson
          euRespPerson={this.eu_resp_person}
          euRespPersonDetail={this.eu_resp_persopn_detail}
          language={this.language}
        /> : null}
      {this.descriptionReady ? 
        <DescriptionDrawer
          show-drawer={this.descriptionStatus}
          closeDescriptionPop={this.closeDescriptionPop}
          showEvoluEntry={this.showEvoluEntry}
          showEvolushein={this.showEvolushein}
          language={this.language}
          multiDescription={this.multiDescription}
          descriptionList={this.descriptionList}
          content={this.drawerContent}
          title={this.pageComponents?.descriptionConf?.descriptionName || ''}
          sellingPoints={this.sellingPoints}
          $router={this.$router}
          trendsInfo={this.trendsInfo}
          trendsImg={this.inFashionStoreImg}
          detail={this.detail}
        /> 
        : null}
      {this.userManualReady ?
        <UserManualDrawer
          key={this.detail.instruction_url}
          userManualReady={this.userManualReady}
          show-drawer={this.userManualStatus}
          closeUserManualPop={this.closeUserManualPop}
          language={this.language}
          content={this.detail.instruction_url}
        /> : null
      }
      { renderIngredientEnter() }
      {(this.showSizeGuide && sizeGuideConf?.isShow) ?
        <SizeGuideEnter class="product-middle__has-border" />
        : null}
      {this.showModelIntro ?
        <ModelIntro
          currentLocalCountry={this.currentLocalCountry}
          localsize={this.localsize}
          parentCats={this.parentCats}
          moduleRenderConfig={this.moduleRenderConfig}
          modelContent={this.model}
          language={this.language}
          unitType={this.unitType}
          sizeGuidPlanA={this.sizeGuidPlanA}
          style={{ paddingTop: this.sizeInfoDes?.sizeInfo?.length && sizeGuideConf.isShow ? '0' : '.32rem' }}
        />
        : null}
    </div>
  },
}
</script>
  
<style lang="less">
.goods-attr {
  &__title {
    font-weight: bold;
    .font-dpr(28px);
    line-height: 1.14;
    padding: 0.2667rem 0 0;
    /* rw:begin */
    font-family: 'Adieu';
  }
}

.product-middle {
  &__has-border {
    border-top: 1px solid #e5e5e5;
  }
}
</style>
  
