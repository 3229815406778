<template>
  <div
    v-if="Object.keys(sheinFotterInfo).length"
    class="footer-sheininfo"
    :da-expose-code="analysisInfo.exposeCode"
  >
    <p
      v-if="titleRequired && sheinFotterInfo && !!sheinFotterInfo['titleList'].length"
      class="footer-sheininfo__title"
      :class="{active: true}"
      tabindex="0"
      role="text"
      :aria-label="sheinFotterInfo['titleGroup']"
      :data-eventcategory="analysisInfo.eventCategory"
      da-event-click="2-21-2"
      @tap="sliderEvent"
    >
      {{ sheinFotterInfo['titleGroup'] }}<i
        v-if="arrow=='title'"
        class="suiiconfont sui_icon_more_up_16px"
      ></i>
    </p>
    <div
      v-if="sheinFotterInfo['titleList']"
      class="footer-sheininfo__content j-expose__footer-sheininfo"
      :class="{active: true, 'footer-sheininfo__content-home': dataType == 'home_bottom'}"
      @transitionend="transitionend"
      @webkitTransitionEnd="transitionend"
    >
      <template v-for="(item,index) in sheinFotterInfo['titleList']">
        <a
          v-if="dataType == 'home_bottom' || index<6"
          :key="index"
          class="footer-sheininfo__content__link j-expose__footer-sheininfo__link"
          :data-seat="`${index+1}'${item.title}`"
          :data-eventcategory="analysisInfo.eventCategory"
          :data-expose-id="`footer-sheininfo-${index + 1}-${item.title}`"
          da-event-click="2-21-2"
          da-event-expose="2-21-1"
          href="javascript:;"
          tabindex="0"
          :aria-label="item.title"
          role="link"
          @keydown.enter.prevent="linkToArticle(item.jumpType==1 ? `${url}/${item.article.relativeUrl}-a-${item.value}.html`: item.value)"
          @click="linkToArticle(item.jumpType==1 ? `${url}/${item.article.relativeUrl}-a-${item.value}.html`: item.value)"
        >
          <div
            class="j-expose__footer-sheininfo__link-con"
            style="height: 100%"
          >
            {{ item.title }}
            <i
              v-if="arrow=='content'"
              class="suiiconfont sui_icon_more_right2_16px"
            ></i>
          </div>
        </a>
      </template>
      <a
        v-if="dataType == 'category_bottom'"
        href="javascript:;"
        @click="linkToArticle(`${url}/Privacy-Center-a-1045.html`)"
      >{{ privacyLanguage }}</a>
      <a
        v-if="(IS_SUPPORT_ONETRUST || IS_SUPPORT_COOKIEBANNER) && dataType == 'category_bottom'"
        href="javascript: void(0);"
        @click="handleOneTrustMangeCookie"
      >{{ cookieLanguage }}</a>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-21' })
import { footFetch } from './core.js'

export default {
  name: 'FooterSheinInfo',
  props: {
    titleRequired: {
      type: Boolean,
      required: true
    },
    arrow: {
      type: String,
      default: 'top'
    },
    transitionend: {
      type: Function,
      default: function () {}
    },
    dataType: {
      type: String,
      required: true,
      default: 'home_bottom'
    },
    analysisInfo: {
      type: Object,
      default() {
        return {
          eventCategory: '',
          exposeCode: ''
        }
      }
    },
    // 频道id,目前只有dataType=home_bottom有用
    channelId: {
      type: [String, Number],
      default: ''
    }
  },

  data () {
    return {
      url: '',
      hasTab: false,
      cookieLanguage: '',
      usCookieLanguage: '',
      IS_SUPPORT_ONETRUST: false,
      IS_SUPPORT_COOKIEBANNER: false,
      backupMarginTop: null,
      isInitFixed: false,
      privacyLanguage: '',
      daEventExpose: '',
      isUsSite: false,
      sheinFotterInfo: {},
    }
  },

  watch: {
    '$route'() {
      requestIdleCallback(() => {
        const exposeCode = this.analysisInfo.exposeCode
        this.daEventExpose?.reset?.(exposeCode)
        this.$nextTick(() => {
          this.daEventExpose?.update?.(exposeCode)
        })
      })
    },
    // 切换tab改变channelId需要重新请求
    channelId() {
      this.getSheinInfo()
    }
  },
  
  created () {
    this.getSheinInfo()
  },

  mounted () {
    const { langPath, language, IS_SUPPORT_ONETRUST, SiteUID, CUSTOM_PRIVACY_SUPPORT, IS_SUPPORT_COOKIEBANNER } = gbCommonInfo
    this.url = `${location.origin}${langPath}`
    const isUsSite = ['rwmus', 'pwus'].includes(SiteUID)
    this.isUsSite = isUsSite
    this.cookieLanguage = isUsSite ? language.SHEIN_KEY_PWA_19007 : language.SHEIN_KEY_PWA_18057
    this.SiteUID = SiteUID
    this.IS_SUPPORT_ONETRUST = IS_SUPPORT_ONETRUST
    this.IS_SUPPORT_COOKIEBANNER = IS_SUPPORT_COOKIEBANNER
    this.CUSTOM_PRIVACY_SUPPORT = CUSTOM_PRIVACY_SUPPORT
    this.privacyLanguage = language.SHEIN_KEY_PWA_18362
    this.usCookieLanguage = language.SHEIN_KEY_PWA_18450
  },

  methods: {
    ...mapMutations(['changeRootStatus']),

    async getSheinInfo () {
      const data = await footFetch({ dataType: this.dataType, channelId: this.channelId })
      this.sheinFotterInfo = data
      this.$nextTick(() => {
        this.initExpose()
        this.transitionend()
      })
    },

    initExpose() {
      this.daEventExpose = daEventCenter.getExposeInstance()
      this.daEventExpose.subscribe({
        keycode: `${this.analysisInfo.exposeCode}\`2-21-1`
      })
    },

    sliderEvent () {
      if (this.arrow != 'title') return
      if (this.hasTab) return
      this.hasTab = true
      setTimeout(() => {
        this.hasTab = false
      }, 500)
    },
    linkToArticle (link) {
      if (this.hasTab) return
      this.hasTab = true
      window.location.href = link
      this.hasTab = false
    },
    handleOneTrustMangeCookie () {
      // 修复侧边栏弹出之后，onetrust弹窗不能滚动
      this.changeRootStatus({ show_category: false })
      // eslint-disable-next-line no-undef
      
      this.$nextTick(() => {
        if (this.CUSTOM_PRIVACY_SUPPORT.includes(this.SiteUID)) {
          window.PrivacySDK?.PrivacySDK?.getSingleton()?.openPreference()
        } else {
          window.OneTrust.ToggleInfoDisplay()
          this.backupMarginTop = document.body.style.marginTop
          this.onetrustIosFix()
        }
      })
    },
    // branch banner 在ios上的影响修复
    onetrustIosFix () {
      if (window.onetrustBannerLoadedError) return
      if (this.isInitFixed) return
      if (this.backupMarginTop === '' || this.backupMarginTop === '0px') return
      const ua = navigator.userAgent
      // 非ios
      if (!/iphone|ipad|ipod/i.test(ua)) return
      this.isInitFixed = true
      const targetNode = document.getElementById('onetrust-pc-sdk')
      const config = { attributes: true, childList: false, subtree: false }
      // 当观察到变动时执行的回调函数
      const callback = (mutationsList) => {
        for(let mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            if (targetNode.style.display === 'none') {
              return this.fixBranchBannerAtrribute(this.backupMarginTop)
            }
            this.fixBranchBannerAtrribute(0)
          }
        }
      }
      const observer = new MutationObserver(callback)
      observer.observe(targetNode, config)
    },
    fixBranchBannerAtrribute (v) {
      document.body.style.marginTop = v
    }
  }
}
</script>

<style lang="less">
.footer-sheininfo{
    &.sidecate{
        .footer-sheininfo__content{
            a{
                height: 0.96rem;
                line-height: 0.96rem;
            }
        }

        [class*="iconfont"]{
            line-height: 0.96rem;
        }
    }
    &__title{
        position: relative;
        height: 0.96rem;
        line-height: 0.96rem;
        font-weight: bold;
        .font-dpr(28px);
        color: #222;
        [class*="iconfont"]{
            transition: all .3s;
        }
        &.active{
            [class*="iconfont"]{
                transform:rotate(180deg);
            }
        }
    }
    &__content{
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s;
        &.active{
          max-height: 11.5rem;
        }
        &.footer-sheininfo__content-home {
          max-height: initial;
        }
        a{
            position: relative;
            display: inline-block;
            width: 100%;
            height: 1.17rem;
            line-height: 1.17rem;
            padding: 0;
            .font-dpr(28px);
            color: #222;
            overflow: hidden;
            text-decoration: none;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    [class*="iconfont"]{
        position: absolute;
        right: 0;
        top: 0;
        display: inline-block;
        line-height: 1.17rem;
        font-weight: normal;
        color: #969696;
        font-size: 16px;
    }

    &.vertical{
        .footer-sheininfo__content{
            text-align: center;
        }
        a{
            padding: 0 0.32rem;
            width: auto;
            .font-dpr(24px);
        }
    }
}
</style>
