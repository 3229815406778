let eventInit = false

function checkAllowShow() {
  var route = window._gb_app_ && window._gb_app_.$route || {}
  var name = route.name
  var supportPage = ['config_index', 'search', 'page_goods_detail', 'page_select_class', 'page_category', 'shein_picks', 'page_real_class', 'page_daily_new']
  return supportPage.includes(name)
}
function show(ins) {
  if (ins) {
    if (checkAllowShow()) {
      ins.showAgreementIfNeed()
    }
    if (!eventInit) {
      eventInit = true
      window._gb_app_ && window._gb_app_.$watch('$route', () => {
        if (checkAllowShow()) {
          ins.showAgreementIfNeed()
        } else {
          ins.removeAgreement()
        }
      })
    }
  }
}

export function showCookieBanner() {
  try {
    if (!window._gb_app_) {
      window.addEventListener('gbAppInfoInited', function () {
        var ins = window.PrivacySDK.PrivacySDK.getSingleton()
        show(ins)
      })
    } else {
      var ins = window.PrivacySDK.PrivacySDK.getSingleton()
      show(ins)
    }

  } catch (e) {
    console.error(e)
  }
}
