

export default {
  namespaced: true,
  state: {
    eu_resp_persopn_detail: null,
  },
  getters: {
    eu_resp_person(state, getters, rootState, rootGetters) {
      const productMiscInfo = rootState.newProductDetail.coldModules?.productInfo?.productMiscInfo || {}
      let { euRespPerson } = productMiscInfo
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const openGPSRTips = fsAbt?.goodsdetailscompliance?.param?.R_gpsr_tips === 'on' || false
      return {
        showGPSRTips: euRespPerson?.euRespTitle && openGPSRTips,
        ...euRespPerson,
      }
    },
    eu_resp_persopn_detail (state, getters, rootState) {
      const productMiscInfo = rootState.newProductDetail.coldModules?.productInfo?.productMiscInfo || {}
      let { euRespPersonDetail } = productMiscInfo
      return euRespPersonDetail
    }
  },
}
