/* eslint-disable require-atomic-updates */
import { newPrivacyCache, dataVisorCb, urlRedirection, formatNormalErrorMessage, isFromShopping } from '../util'
import { fbLogin, googleLogin, vkLogin, lineLoginSer, checkRelationAccSer, getMemeberList, kakaoLoginSer, naverLoginSer } from '../service'
import { callFaceBookLogin, callGoogleLogin, callVKLogin, callLineLogin, callKakaoLogin, callNaverLogin } from '../common/thirdLoginMethods'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { riskVerifyChallenge } from '@login/common/riskVerifyManager.js'
import { mapMutations, mapState, mapActions } from 'vuex'
import { saSend } from '../analysis/util'
daEventCenter.addSubscriber({ modulecode: '2-8' })

let signupTimeTag = null

export default {
  data: () => ({
    vkInfo: {}, // vk三方接口返回vk用户信息
    thirdEmailLoginInfo: { show: false, type: '' },
  }),
  computed: {
    ...mapState('login', ['langText', 'newPrivacyAbt', 'isGoogleSupport', 'defaultLocation', 'commonAbt', 'relatedAcc', 'isAsComponent', 'fuseAbtResult', 'phoneAbt', 'isNewRegisterPop', 'newUIStates', 'preloadData', 'publicLocalData']),
    isShow () {
      // in站关闭三方注册入口
      if (['pwin', 'rwmin'].includes(this.publicLocalData?.SiteUID)) return false
      // 来自关联账号的组件，不需要展示，作为$ref来调用内部方法
      if (this.from == 'relation_account') return false
      // 来自关联账号 && 关联分支为free && 关联账号含有三方类型
      if (this.relatedAcc.global && this.relatedAcc.abt == 'free' && this.relatedAcc.third_parts?.length > 0) return true
      // 来自关联账号登陆操作
      if (this.relatedAcc.global) return false
      return true
    },
    isShowFacebook () {
      if (this.relatedAcc.global && !this.relatedAcc.third_parts?.includes(11)) return false
      return true
    },
    isShowGoogle () {
      if (this.relatedAcc.global && !this.relatedAcc.third_parts?.includes(12)) return false
      return this.isGoogleSupport
    },
    isShowVk () {
      if (this.relatedAcc.global && !this.relatedAcc.third_parts?.includes(13)) return false
      return this.publicLocalData?.SiteUID == 'mru'
    },
    isShowLine () {
      if (this.relatedAcc.global && !this.relatedAcc.third_parts?.includes(15)) return false
      return this.commonAbt.lineLogin == 'on'
    },
    isShowKakao () {
      if (this.relatedAcc.global && !this.relatedAcc.third_parts?.includes(16)) return false
      return this.commonAbt.kakaoLogin == 'yes'
    },
    isShowNaver () {
      if (this.relatedAcc.global && !this.relatedAcc.third_parts?.includes(17)) return false
      return this.commonAbt.naverLogin == 'yes'
    }
  },
  methods: {
    ...mapMutations('login', ['setNewPrivacyModal', 'setPhoneBind', 'setnewUIStates', 'assignState', 'changeDialogs']),
    ...mapActions('login', ['setRegisterPopData']),
    // signupTime, signupTimeTag 定义在 index.js开始部分
    signupTimeRecord () {
      if (window.signupTime !== undefined && window.signupTime === 0) {
        window.signupTimeTag = setInterval(() => {
          window.signupTime = window.signupTime + 100
        }, 100)
      }
    },
    handleRelationButton (register_from) {
      if (register_from == '11') {
        this.handleFacebookBtn()
        return
      }
      if (register_from == '12') {
        this.handleGoogleBtn()
        return
      }
      if (register_from == '13') {
        this.vkLoginFun()
        return
      }
      // if (register_from == '14') {
      //   this.handleAppleClick()
      //   return
      // }
      if (register_from == '15') {
        this.handleLineLogin()
        return
      }
      if (register_from == '16') {
        this.handleKakaoLogin()
        return
      }
      if (register_from == '17') {
        this.handleNaverLogin()
        return
      }
    },
    /**
       * 校验3方关联账号是否与关联账号一致
       * @param {string} clientid 3方应用id
       * @param {string} id 3方登陆账号id
       * @param {string} thirdType 3方登陆账号类型， 11: fb, 12: google, 13: vk, 14: apple, 15: line, 16: kakao,  17: naver
       */
    async checkRelationAcc (id, thirdType ) {
      const { from } = this.relatedAcc
      const data = {
        scene: from == 'order_list' ? 'order_list' : 'login_register',
        relation_token: this.relatedAcc.selected.relation_token,
        third_party_id: id,
        third_party_type: thirdType,
      }
      const { code, tips, info } = await checkRelationAccSer(data)

      if (code == 0 && info?.result == 1) {
        return { pass: true }
      }

      if (code == 0 && info?.result == 0) { // 不匹配
        this.$toast(this.langText.SHEIN_KEY_PWA_22080, 2500)
        return { pass: false, tips: this.langText.SHEIN_KEY_PWA_22080 }
      }
      this.$toast(this.langText.SHEIN_KEY_PWA_14899, 2500)
      return { pass: false, tips: tips || this.langText.SHEIN_KEY_PWA_14899 }
    },
    async checkAccountList (res, third) {
      const { account_list } = await getMemeberList() || {}
      // 如果已经绑定手机号码
      if (account_list && account_list.some((v) => v.alias_type == 2)) {
        this.setnewUIStates({ isToLogin: false, })
        return urlRedirection(false, res)
      }
      this.$evt.$emit('event-loading', false)
      this.setPhoneBind({ show: true, scene: 'login', response: res, email: '', third })
    },
    handleGoogleBtn () {
      saSend('2-8-102', { third_party: 'google', ispop: this.isAsComponent, acc: this.relatedAcc })
      if (this.fuseAbtResult.google) {
        // this.$toast(this.langText.SHEIN_KEY_PWA_17115)
        this.showThirdEmailLogin('Google')
        return 
      }
      this.signupTimeRecord()
      this.attachGoogleSign()
    },
    handleFacebookBtn () {
      saSend('2-8-102', { third_party: 'facebook', ispop: this.isAsComponent, acc: this.relatedAcc })
      if (this.fuseAbtResult.fb) {
        // this.$toast(this.langText.SHEIN_KEY_PWA_17115)
        this.showThirdEmailLogin('Facebook')
        return 
      }
      this.signupTimeRecord()
      this.facebookLogin()
    },
    handleTAndCProtocol () {
      window.location.href = `${this.publicLocalData?.langPath}/Terms-and-Conditions-a-399.html`
    },
    vkLoginFun () {
      saSend('2-8-102', { third_party: 'vk', ispop: this.isAsComponent, acc: this.relatedAcc })
      if (this.fuseAbtResult.vk) {
        this.showThirdEmailLogin('VK')
        return 
      }
      const redirect_uri = `${this.publicLocalData?.host}${this.publicLocalData?.langPath}/vklogin/auth?s=login`
      callVKLogin(redirect_uri, (info) => {
        this.requestVkLogin(info)
      })
    },
    // source 0 不验证是否是已使用的邮箱
    async requestVkLogin (info) {
      this.vkInfo = info
      const opts = {
        user_id: info.user_id,
        email: info.email,
        app_token: info.access_token,
        email_source: 0,
        third_email: 1
      }
      opts.clause_flag = 0
      if (this.newPrivacyAbt.type == 'yes') {
        opts.clause_flag = 1
        if (newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')) {
          opts.clause_agree = 1
        }
      }
      opts.locationId = this.newPrivacyAbt.origin == 'mix' ? ( this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
      
      this.activityName && (opts.activity_name = this.activityName)

      if (this.relatedAcc.global && this.relatedAcc.abt == 'related') { // 3方关联账号查询
        const { pass } = await this.checkRelationAcc(opts.user_id, 13)
        // 关联账号校验不通过
        if (!pass) { return }
        // 如果校验通过，添加参数
      }
      if (this.relatedAcc.global) opts.relation_scene = 1
      const { isSwitch } = this.newUIStates
      isSwitch ? opts.isSwitchUid = this.preloadData?.uid : ''

      vkLogin(opts, (err, res) => {
        if (err !== null) {
          this.$evt.$emit('request-vkLogin-error', err)
          // const data = JSON.parse(xhr.responseText)
          // data.msg && alert(data.msg)
          return
        }
        res.code = parseInt(res.code, 10)
        const { code, info } = res

        if (code == 0) { // 登录成功
          saSend('2-8-103', { third_party: 'vk', acc: this.relatedAcc, isRegister: info?.member?.isRegister, isAsComponent: this.isAsComponent })
          clearInterval(signupTimeTag)
          // 引导绑定手机号码, abt:emailloginBindPhone 开启 && 来自非购物流程 && 关联账号引导登陆中来自订单列表
          if (this.relatedAcc.global && this.phoneAbt.emailloginBindPhone == 'on' && !isFromShopping() && this.relatedAcc.from != 'order_list') {
            this.checkAccountList(res, 'VK')
            return
          }
          if (info && info.member && info.member.isRegister) {
            this.setnewUIStates({ isToLogin: false, })
            this.showRegisterSuccessPop(res, info.email, 'VK')
          } else {
            this.setnewUIStates({ isToLogin: false, })
            setTimeout(function () {
              urlRedirection(false, res)
            }, 200)
          }
          return
        }
        this.$evt.$emit('event-loading', false)
        if(code == 402922){
          riskVerifyChallenge(res, opts, ({ paramsData, type }) => {
            if(type == 'error') {
              this.$evt.$emit('event-loading', false)
              this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
              return
            }
            if(type == 'close') {
              this.$evt.$emit('event-loading', false)
              return
            }
            this.requestVkLogin(paramsData)
          })
          return
        }
        if (code == 400593) {
          this.setNewPrivacyModal({
            from: 'VK',
            cb: () => {
              this.$evt.$emit('event-loading', true)
              opts.clause_agree = 1
              this.requestVkLogin(opts)
            }
          })
          return
        }

        if (res.code == 400507 || res.code == 400583) { // 未绑定邮箱
          this.$emit('show-bind-email', {
            accessToken: opts.app_token,
            socialId: opts.user_id,
            type: 'bind',
            source: 'VK'
          })
          return
        }

        if (res.code == 400540) { // 社交媒体账号重复
          const repeatType = res.info.type
          this.$emit('show-bind-email', {
            accessToken: opts.app_token,
            socialId: opts.user_id,
            email: opts.email,
            repeatType: res.info.type,
            // 7 为普通邮箱账号
            type: repeatType.includes(7) ? 'loginBind' : 'third',
            source: 'VK'
          })
          this.$emit('act-account-repeat')
          return
        }

        // 重复渠道, 拦截
        if (res.code == 400542) {
          this.$emit('show-bind-email', {
            accessToken: opts.app_token,
            socialId: opts.user_id,
            type: 'failure',
            source: 'VK',
            isDirectFailure: true
          })
          this.$emit('act-account-repeat')
        }

        this.$toast(formatNormalErrorMessage(res))
      })
    },
    // 调用Facebook官方API
    facebookLogin () {
      const FBMessengerConnect = this.commonAbt.FBMessengerConnect
      callFaceBookLogin(FBMessengerConnect, (token, uid, email) => {
        const data = {
          email: email,
          facebook_id: uid,
          fbtoken: token,
          invite_agency: 0,
          invite_agency_token: this.invite_agency_token || 0,
          register_type: 'facebook',
          email_source: 0,
          third_email: 1
        }
        data.clause_flag = 0
        if (this.newPrivacyAbt.type == 'yes') {
          data.clause_flag = 1
          if (newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')) {
            data.clause_agree = 1
          }
        }
        data.locationId = this.newPrivacyAbt.origin == 'mix' ? ( this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
        this.activityName && (data.activity_name = this.activityName)
        dataVisorCb(data, (data) => this.requestFbLogin(data))
      })
    },
    // 调用shein的Facebook登录接口
    async requestFbLogin (data) {
      if (this.relatedAcc.global && this.relatedAcc.abt == 'related') { // 3方关联账号查询
        const { pass } = await this.checkRelationAcc(data.facebook_id, 11)
        // 关联账号校验不通过
        if (!pass) { return }
      }
      if (this.relatedAcc.global) data.relation_scene = 1
      const { isSwitch } = this.newUIStates
      isSwitch ? data.isSwitchUid = this.preloadData?.uid : ''
      fbLogin(data, (err, res) => {
        if (err !== null) {
          this.$evt.$emit('request-fblogin-error', err)
          // const data = JSON.parse(xhr.responseText)
          // data.msg && alert(data.msg)
          return
        }
        res.code = parseInt(res.code, 10)
        const { code, info } = res
      
        if (code == 0) { // 登录成功
          saSend('2-8-103', { third_party: 'facebook', acc: this.relatedAcc, isRegister: info?.member?.isRegister, isAsComponent: this.isAsComponent })
          clearInterval(signupTimeTag)
          // 引导绑定手机号码, abt:emailloginBindPhone 开启 && 来自非购物流程 && 关联账号引导登陆中来自订单列表
          if (this.relatedAcc.global && this.phoneAbt.emailloginBindPhone == 'on' && !isFromShopping() && this.relatedAcc.from != 'order_list') {
            this.checkAccountList(res, 'Facebook')
            return
          }
          if (info && info.member && info.member.isRegister) {
            this.setnewUIStates({ isToLogin: false, })
            this.showRegisterSuccessPop(res, data.email, 'Facebook')
          } else {
            this.setnewUIStates({ isToLogin: false, })
            setTimeout(function () {
              urlRedirection(false, res)
            }, 200)
          }
          return
        }
       
        this.$evt.$emit('event-loading', false)
        if(code == 402922){
          riskVerifyChallenge(res, data, ({ paramsData, type }) => {
            if(type == 'error') {
              this.$evt.$emit('event-loading', false)
              this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
              return
            }
            if(type == 'close') {
              this.$evt.$emit('event-loading', false)
              return
            }
            this.requestFbLogin(paramsData)
          })
          return
        }
        if (code == 400593) {
          this.setNewPrivacyModal({
            from: 'Facebook',
            cb: () => {
              this.$evt.$emit('event-loading', true)
              data.clause_agree = 1
              this.requestFbLogin(data)
            }
          })
          return
        }
     
        if (res.code == 400507 || res.code == 400583) { // 未绑定邮箱
          this.$emit('show-bind-email', {
            accessToken: data.fbtoken,
            socialId: data.facebook_id,
            type: 'bind',
            source: 'Facebook'
          })
          return
        }
      
        if (res.code == 400540) { // 社交媒体账号重复
          const repeatType = res.info.type
          this.$emit('show-bind-email', {
            accessToken: data.fbtoken,
            socialId: data.facebook_id,
            email: data.email,
            repeatType: res.info.type,
            // 7 为普通邮箱账号
            type: repeatType.includes(7) ? 'loginBind' : 'third',
            source: 'Facebook'
          })
          this.$emit('act-account-repeat')
          return
        }
       
        // 重复渠道, 拦截
        if (res.code == 400542) {
          this.$emit('show-bind-email', {
            accessToken: data.fbtoken,
            socialId: data.facebook_id,
            type: 'failure',
            source: 'Facebook',
            isDirectFailure: true
          })
          this.$emit('act-account-repeat')
          return
        }
    
        this.$toast(formatNormalErrorMessage(res))
      })
    },
    // 绑定Google登录点击事件
    attachGoogleSign () {
      callGoogleLogin((data) => {
        const { code, id, email, id_token } = data || {}
        if (code == 0) {
          const { type, origin } = this.newPrivacyAbt || {}
          const locationId = origin == 'mix' ? (this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
          const params = { 
            google_id: id,
            email, 
            id_token, 
            register_tyoe: 'google',
            email_source: 0, 
            clause_flag: type == 'yes' ? 1 : 0,
            locationId,
          }
          this.requestGoogleLogin(params)
          return
        }
        this.$toast(this.langText.SHEIN_KEY_PWA_14899)
      })
    },
    // 请求shein的Google登录接口
    async requestGoogleLogin (reqData) {
      if (this.relatedAcc.global && this.relatedAcc.abt == 'related') { // 3方关联账号查询
        const { pass } = await this.checkRelationAcc(reqData.google_id, 12)
        // 关联账号校验不通过
        if (!pass) { return }
      }
      if (this.relatedAcc.global) reqData.relation_scene = 1
      const { isSwitch } = this.newUIStates
      isSwitch ? reqData.isSwitchUid = this.preloadData?.uid : ''
      googleLogin(reqData, (err, res) => {
        if (err !== null) {
          this.$evt.$emit('request-googleLogin-error')
          alert(this.langText.SHEIN_KEY_PWA_15409)
          return
        }

        res.code = parseInt(res.code, 10)

        const { code, info } = res

        if (code == 0 && info) { // 登录成功
          saSend('2-8-103', { third_party: 'google', acc: this.relatedAcc, isRegister: info?.member?.isRegister, isAsComponent: this.isAsComponent })
          clearInterval(signupTimeTag)
          // 引导绑定手机号码, abt:emailloginBindPhone 开启 && 来自非购物流程 && 关联账号引导登陆中来自订单列表
          if (this.relatedAcc.global && this.phoneAbt.emailloginBindPhone == 'on' && !isFromShopping() && this.relatedAcc.from != 'order_list') {
            this.checkAccountList(res, 'Google')
            return
          }
          if (info && info.member && info.member.isRegister) {
            this.setnewUIStates({ isToLogin: false, })
            this.showRegisterSuccessPop(res, reqData.email, 'Google')
          } else {
            this.setnewUIStates({ isToLogin: false, })
            setTimeout(function () {
              urlRedirection(false, res)
            }, 200)
          }
          return
        }
        this.$evt.$emit('event-loading', false)
        if(code == 402922){
          riskVerifyChallenge(res, reqData, ({ paramsData, type }) => {
            if(type == 'error') {
              this.$evt.$emit('event-loading', false)
              this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
              return
            }
            if(type == 'close') {
              this.$evt.$emit('event-loading', false)
              return
            }
            this.requestGoogleLogin(paramsData)
          })
          return
        }
        if (code == 400593) {
          this.setNewPrivacyModal({
            from: 'Google',
            cb: () => {
              this.$evt.$emit('event-loading', true)
              reqData.clause_agree = 1
              this.requestGoogleLogin(reqData)
            }
          })
          return
        }

        if (res.code == 400540) { // 社交媒体账号重复
          const repeatType = res.info.type
          this.$emit('show-bind-email', {
            accessToken: reqData.id_token,
            socialId: reqData.google_id,
            email: reqData.email,
            repeatType: res.info.type,
            // 7 为普通邮箱账号
            type: repeatType.includes(7) ? 'loginBind' : 'third',
            source: 'Google'
          })
          return
        }

        if (+res.code == 400540) { // 与社交媒体账号冲突
          this.$emit('act-account-repeat')
        }

        // 重复渠道, 拦截
        if (res.code == 400542) {
          this.$emit('show-bind-email', {
            accessToken: reqData.id_token,
            socialId: reqData.google_id,
            type: 'failure',
            source: 'Google',
            isDirectFailure: true
          })
          this.$emit('act-account-repeat')
        }

        this.$toast(formatNormalErrorMessage(res))
      })
    },
    // kakao点击
    handleKakaoLogin () {
      saSend('2-8-102', { third_party: 'kakao', ispop: this.isAsComponent, acc: this.relatedAcc })
      if (this.fuseAbtResult.kakao) {
        this.showThirdEmailLogin('Kakao')
        return 
      }
      callKakaoLogin(data => {
        const { code, profile, kakao_token, error_description, error = '' } = data
        window.sa?.('send', { activity_name: 'expose_kakao_call_back', activity_param: { code: error, line_msg: error_description } })
        if (code != 0 ) {
          this.$toast(error_description || this.langText.SHEIN_KEY_PWA_14899)
          return
        }

        const isNewPrivacyType = this.newPrivacyAbt.type == 'yes'
        const params = {
          email: profile?.kakao_account?.email || '',
          kakao_id: profile.id,
          kakao_token,
          third_email: profile?.kakao_account?.has_email ? 1 : 0,
          clause_flag: isNewPrivacyType ? 1 : 0
        }
        params.locationId = this.newPrivacyAbt.origin == 'mix' ? ( this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
        if (isNewPrivacyType && newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')) {
          params.clause_agree = 1
        }
        this.requestKakaoLogin(params)
      })
    },
    async requestKakaoLogin (params) {
      if (this.relatedAcc.global && this.relatedAcc.abt == 'related') { // 3方关联账号查询
        const { pass } = await this.checkRelationAcc(params.kakao_id, 16)
        // 关联账号校验不通过
        if (!pass) { return }
      }
      if (this.relatedAcc.global) params.relation_scene = 1
      const { isSwitch } = this.newUIStates
      isSwitch ? params.isSwitchUid = this.preloadData?.uid : ''

      const response = await kakaoLoginSer(params)
      const { code, info } = response

      if (code == 0) {
        const isRegister = info?.member?.isRegister || false
        saSend('2-8-103', { third_party: 'Kakao', acc: this.relatedAcc, isRegister, isAsComponent: this.isAsComponent })
        if (isRegister) {
          this.setnewUIStates({ isToLogin: false, })
          this.showRegisterSuccessPop(response, params.email, 'Kakao')
          return
        }
        // 引导绑定手机号码, abt:emailloginBindPhone 开启 && 来自非购物流程 && 关联账号引导登陆中来自订单列表
        if (this.relatedAcc.global && this.phoneAbt.emailloginBindPhone == 'on' && !isFromShopping() && this.relatedAcc.from != 'order_list') {
          this.checkAccountList(response, 'Kakao')
          return
        }
        this.setnewUIStates({ isToLogin: false, })
        urlRedirection(false, response)
        return
      }

      this.$evt.$emit('event-loading', false)
      if(code == 402922){
        riskVerifyChallenge(response, params, ({ paramsData, type }) => {
          if(type == 'error') {
            this.$evt.$emit('event-loading', false)
            this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.$evt.$emit('event-loading', false)
            return
          }
          this.requestKakaoLogin(paramsData)
        })
        return
      }
      if (code == 400593) { // 强合规但用户未同意
        this.setNewPrivacyModal({
          from: 'Kakao',
          cb: () => {
            this.$evt.$emit('event-loading', true)
            params.clause_agree = 1
            this.requestKakaoLogin(params)
          }
        })
        return
      }

      if (code == 400540) { // 社交媒体账号重复
        const repeatType = info.type
        this.$emit('show-bind-email', {
          accessToken: params.kakao_token,
          socialId: params.kakao_id,
          email: params.email,
          repeatType,
          // 7 为普通邮箱账号
          type: repeatType.includes(7) ? 'loginBind' : 'third',
          source: 'Kakao'
        })
        this.$emit('act-account-repeat')
        return
      }

      if (code == 400507) { // 未绑定邮箱
        this.$emit('show-bind-email', {
          accessToken: params.kakao_token,
          socialId: params.kakao_id,
          type: 'bind',
          source: 'Kakao'
        })
        return
      }

      if (code == 400542) { // 重复渠道, 拦截
        this.$emit('show-bind-email', {
          accessToken: params.kakao_token,
          socialId: params.kakao_id,
          type: 'failure',
          source: 'Kakao',
          isDirectFailure: true
        })
        this.$emit('act-account-repeat')
        return
      }      
      this.$toast(formatNormalErrorMessage(response))
    },
    // naver点击
    handleNaverLogin () {
      saSend('2-8-102', { third_party: 'naver', ispop: this.isAsComponent, acc: this.relatedAcc })
      if (this.fuseAbtResult.naver) {
        this.showThirdEmailLogin('Naver')
        return 
      }
      callNaverLogin(data => {
        const { code, profile, naver_token, error_description, error = '' } = data
        window.sa?.('send', { activity_name: 'expose_naver_call_back', activity_param: { code: error, line_msg: error_description } })
        if (code != 0 ) {
          this.$toast(error_description || this.langText.SHEIN_KEY_PWA_14899)
          return
        }

        const isNewPrivacyType = this.newPrivacyAbt.type == 'yes'
        const params = {
          email: profile?.email || '',
          naver_id: profile.id,
          naver_token,
          third_email: profile?.email ? 1 : 0,
          clause_flag: isNewPrivacyType ? 1 : 0
        }
        params.locationId = this.newPrivacyAbt.origin == 'mix' ? ( this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
        if (isNewPrivacyType && newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')) {
          params.clause_agree = 1
        }
        this.requestNaverLogin(params)
      })
    },
    async requestNaverLogin (params) {
      if (this.relatedAcc.global && this.relatedAcc.abt == 'related') { // 3方关联账号查询
        const { pass } = await this.checkRelationAcc(params.naver_id, 17)
        // 关联账号校验不通过
        if (!pass) { return }
      }
      if (this.relatedAcc.global) params.relation_scene = 1
      const { isSwitch } = this.newUIStates
      isSwitch ? params.isSwitchUid = this.preloadData?.uid : ''

      const response = await naverLoginSer(params)
      const { code, info } = response

      if (code == 0) {
        const isRegister = info?.member?.isRegister || false
        saSend('2-8-103', { third_party: 'Naver', acc: this.relatedAcc, isRegister, isAsComponent: this.isAsComponent })
        if (isRegister) {
          this.setnewUIStates({ isToLogin: false, })
          this.showRegisterSuccessPop(response, params.email, 'Naver')
          return
        }
        // 引导绑定手机号码, abt:emailloginBindPhone 开启 && 来自非购物流程 && 关联账号引导登陆中来自订单列表
        if (this.relatedAcc.global && this.phoneAbt.emailloginBindPhone == 'on' && !isFromShopping() && this.relatedAcc.from != 'order_list') {
          this.checkAccountList(response, 'Naver')
          return
        }
        this.setnewUIStates({ isToLogin: false, })
        urlRedirection(false, response)
        return
      }

      this.$evt.$emit('event-loading', false)
      if(code == 402922){
        riskVerifyChallenge(response, params, ({ paramsData, type }) => {
          if(type == 'error') {
            this.$evt.$emit('event-loading', false)
            this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.$evt.$emit('event-loading', false)
            return
          }
          this.requestNaverLogin(paramsData)
        })
        return
      }
 
      if (code == 400593) { // 强合规但用户未同意
        this.setNewPrivacyModal({
          from: 'Naver',
          cb: () => {
            this.$evt.$emit('event-loading', true)
            params.clause_agree = 1
            this.requestNaverLogin(params)
          }
        })
        return
      }

      if (code == 400540) { // 社交媒体账号重复
        const repeatType = info.type
        this.$emit('show-bind-email', {
          accessToken: params.naver_token,
          socialId: params.naver_id,
          email: params.email,
          repeatType,
          // 7 为普通邮箱账号
          type: repeatType.includes(7) ? 'loginBind' : 'third',
          source: 'Naver'
        })
        this.$emit('act-account-repeat')
        return
      }

      if (code == 400507) { // 未绑定邮箱
        this.$emit('show-bind-email', {
          accessToken: params.naver_token,
          socialId: params.naver_id,
          type: 'bind',
          source: 'Naver'
        })
        return
      }

      if (code == 400542) { // 重复渠道, 拦截
        this.$emit('show-bind-email', {
          accessToken: params.naver_token,
          socialId: params.naver_id,
          type: 'failure',
          source: 'Naver',
          isDirectFailure: true
        })
        this.$emit('act-account-repeat')
        return
      }      
      this.$toast(formatNormalErrorMessage(response))
    },
    // line 点击
    handleLineLogin () {
      saSend('2-8-102', { third_party: 'line', ispop: this.isAsComponent, acc: this.relatedAcc })
      if (this.fuseAbtResult.line) {
        this.showThirdEmailLogin('Line')
        return 
      }
      callLineLogin((data) => {
        const { code, id_token, profile, error_description, error = '' } = data
        window.sa?.('send', { activity_name: 'expose_line_call_back', activity_param: { code: error, line_msg: error_description } })
        if (code != 0 ) {
          this.$toast(error_description || this.langText.SHEIN_KEY_PWA_14899)
          return
        }

        const isNewPrivacyType = this.newPrivacyAbt.type == 'yes'
        const params = {
          email: profile.email || '',
          user_id: profile.sub,
          id_token,
          third_email: profile.email ? 1 : 0,
          clause_flag: isNewPrivacyType ? 1 : 0
        }
        params.locationId = this.newPrivacyAbt.origin == 'mix' ? ( this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
        if (isNewPrivacyType && newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')) {
          params.clause_agree = 1
        }
        this.requestLineLogin(params)
      })
    },
    async requestLineLogin (params) {

      if (this.relatedAcc.global && this.relatedAcc.abt == 'related') { // 3方关联账号查询
        const { pass } = await this.checkRelationAcc(params.user_id, 15)
        // 关联账号校验不通过
        if (!pass) { return }
      }
      if (this.relatedAcc.global) params.relation_scene = 1
      const { isSwitch } = this.newUIStates
      isSwitch ? params.isSwitchUid = this.preloadData?.uid : ''

      const response = await lineLoginSer(params)
      
      const { code, info } = response

      if (code == 0) {
        const isRegister = info?.member?.isRegister || false
        saSend('2-8-103', { third_party: 'line', acc: this.relatedAcc, isRegister, isAsComponent: this.isAsComponent })
        if (isRegister) {
          this.setnewUIStates({ isToLogin: false, })
          this.showRegisterSuccessPop(response, params.email, 'Line')
          return
        }
        // 引导绑定手机号码, abt:emailloginBindPhone 开启 && 来自非购物流程 && 关联账号引导登陆中来自订单列表
        if (this.relatedAcc.global && this.phoneAbt.emailloginBindPhone == 'on' && !isFromShopping() && this.relatedAcc.from != 'order_list') {
          this.checkAccountList(response, 'Line')
          return
        }
        this.setnewUIStates({ isToLogin: false, })
        urlRedirection(false, response)
        return
      }

      this.$evt.$emit('event-loading', false)
      if(code == 402922){
        riskVerifyChallenge(response, params, ({ paramsData, type }) => {
          if(type == 'error') {
            this.$evt.$emit('event-loading', false)
            this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.$evt.$emit('event-loading', false)
            return
          }
          this.requestLineLogin(paramsData)
        })
        return
      }
      
      if (code == 400593) {
        this.setNewPrivacyModal({
          from: 'Line',
          cb: () => {
            this.$evt.$emit('event-loading', true)
            params.clause_agree = 1
            this.requestLineLogin(params)
          }
        })
        return
      }

      if (code == 400540) { // 社交媒体账号重复
        const repeatType = info.type
        this.$emit('show-bind-email', {
          accessToken: params.id_token,
          socialId: params.user_id,
          email: params.email,
          repeatType,
          // 7 为普通邮箱账号
          type: repeatType.includes(7) ? 'loginBind' : 'third',
          source: 'Line'
        })
        this.$emit('act-account-repeat')
        return
      }

      if (code == 400507 || code == 400583) { // 未绑定邮箱
        this.$emit('show-bind-email', {
          accessToken: params.id_token,
          socialId: params.user_id,
          type: 'bind',
          source: 'Line'
        })
        return
      }

      // 重复渠道, 拦截
      if (code == 400542) {
        this.$emit('show-bind-email', {
          accessToken: params.id_token,
          socialId: params.user_id,
          type: 'failure',
          source: 'Line',
          isDirectFailure: true
        })
        this.$emit('act-account-repeat')
        return
      }      

      this.$toast(formatNormalErrorMessage(response))
    },
    async showRegisterSuccessPop(res, email, source){
      const registerSuccess =  this.commonAbt.registerSuccess
      if(registerSuccess == 'typeA') {
        await this.setRegisterPopData()
      }
      const isNewRegisterPop = this.isNewRegisterPop
      if(isNewRegisterPop) {
        this.$evt.$emit('event-loading', false)
        this.setnewUIStates({ isToLogin: false, })
        urlRedirection(false, res)
      } else {
        this.$evt.$emit('event-loading', false)
        this.$emit('show-bind-email', {
          isDirectSuccess: true,
          type: 'success',
          email,
          source,
          user: res
        })
      }
    },
    showThirdEmailLogin (type) {
      daEventCenter.triggerNotice({ daId: '2-8-16', extraData: { type } })
      const thirdVal = { show: true, type }
      this.changeDialogs({ key: 'thirdEmailLoginInfo', val: thirdVal })
    },
  }
}
