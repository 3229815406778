import { mapMutations, mapState } from 'vuex'
import { phoneRegisterSer, phoneLoginSer, thirdLoginCodeSend } from '../service'
import { urlRedirection, checkPw, formatNormalErrorMessage, newPrivacyCache } from '../util'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { riskVerifyChallenge } from '@login/common/riskVerifyManager.js'
import analysisIndex from '../analysis/index'
import { saSend } from '../analysis/util'
const { sensorsSend, loginPageFrom } = analysisIndex

export default {
  data: () => ({
    inputCode: '',
    inputCodeTip: { show: false, txt: '' },
    inputCodeIsSending: false,
    inputCodeFocus: false,
    codeDownTime: 0,
    phonePassword: '',
    codePopPasswordTip: { show: false, txt: '' },
    codePopCodeTip: { show: true, txt: '' },
    codeLoading: false,
    codeIsSended: false,
    codeFirstRequest: true, // 第一次请求验证码接口
    sendType: 'sms',
  }),
  computed: {
    ...mapState('login', [
      'areaCode',
      'commonAbt',
      'defaultLocation',
      'newPrivacyAbt',
      'relatedAcc',
      'newUIStates',
      'preloadData',
      'sendTypeChangePop'
    ]),
    whtasAppType() {
      // 判断上次使用渠道， 1 为sms，2位whatsapp
      const parent = typeof window != 'undefined' ? window : {}
      const isUsed = parent?.localStorage?.getItem('l_p_s_c_used') == '2'
      if (this.commonAbt.WhatsAppSetup.default == 'WhatsApp') {
        return { type: ['primary'], class: 'orderIndex', isUsed }
      }
      return { type: ['default'], class: 'orderSecond', isUsed }
    },
    smsType() {
      // 判断上次使用渠道， 1 为sms，2位whatsapp
      const parent = typeof window != 'undefined' ? window : {}
      const isUsed = parent?.localStorage?.getItem('l_p_s_c_used') == '1'
      if (this.commonAbt.WhatsAppSetup.default == 'sms') {
        return { type: ['primary'], class: 'orderIndex', isUsed }
      }
      return { type: ['default'], class: 'orderSecond', isUsed }
    },
  },
  created() {
    this.__codePopTip = this.codePopCodeTip
  },
  methods: {
    ...mapMutations('login', [
      'setShowIndex',
      'setRelatedAcc',
      'assignState',
      'setNewPrivacyModal',
      'setnewUIStates',
      'changeDialogs'
    ]),
    sensorsSend,
    handleCodeBack() {
      this.inputCode = ''
    },
    showInputTips(tip, msg, show = true) {
      if (!tip) return
      tip.show = show
      tip.txt = msg
    },
    handlePopPasswordChange() {
      this.showInputTips(this.codePopPasswordTip, '', false)
    },
    setCodeTipLocation() {
      if (this.codeMode == 'password') this.__codePopTip = this.codePopPasswordTip
      else this.__codePopTip = this.codePopCodeTip
    },
    handleChangeLoginMode() {
      if (this.codeMode == 'code') this.codeMode = 'password'
      else this.codeMode = 'code'

      this.setCodeTipLocation()
    },
    handleInputCodeClick() {
      this.inputCodeFocus = true
    },
    handleInputCodeInput() {
      this.inputCodeFocus = true
      this.showInputTips(this.codePopCodeTip, '', false)
      const v = this.inputCode.replace(/\D/g, '')
      if (v !== this.inputCode) this.inputCode = v
      if (this.inputCode.length > 6) this.inputCode = this.inputCode.slice(0, 6)
    },
    handlePhoneCheckInputParams() {
      this.inputCodeFocus = false
      const { inputCode, phonePassword: password } = this
      this.showInputTips(this.__codePopTip, '', false)
      if (this.codeMode == 'code' && !/\d{6}/.test(inputCode)) {
        this.showInputTips(this.__codePopTip, this.langText.SHEIN_KEY_PWA_16043)
        return false
      }
      if (this.codeMode == 'password') {
        return checkPw(password, this.__codePopTip)
      }
      this.showInputTips(this.__codePopTip, '', false)
      return true
    },
    startDownTime(time) {
      const n = time <= 0 ? 0 : time
      this.codeDownTime = n
      if (this.downtimer) clearTimeout(this.downtimer)
      if (n <= 0) return
      this.downtimer = setTimeout(() => {
        this.startDownTime(n - 1)
      }, 1000)
    },
    analyisContinueSend(code) {
      let daId = ''
      const name = this.cname
      // 合并登陆
      if (name == 'mergeLoginItem') {
        if (this.codeFirstRequest) daId = '2-8-73'
        else daId = '2-8-75'
      }
      if (name == 'mergeSignupItem') {
        if (this.codeFirstRequest) daId = '2-8-74'
        else daId = '2-8-76'
      }
      if (!daId) return
      sensorsSend(daId, { code })
    },
    handleSendTypeResend() {
      this.assignState({
        sendTypeChangePop: false,
      })
      this.handleSendCode('phone_login_register_verify', this.sendType)
    },
    handleSendTypeChange(isChange = true) {
      let smsType = ''
      if (this.sendType == 'sms') {
        smsType = 'whatsApp'
      }
      if (this.sendType == 'whatsApp') {
        smsType = 'sms'
      }
      this.assignState({
        sendTypeChangePop: false,
      })
      if (isChange) this.sendType = smsType
      this.handleSendCode('phone_login_register_verify', smsType)
    },
    /**
     * 验证码发送接口
     * @param {Object} params 请求验证码发送参数
     * @param [string] smsType 验证码发送类型，whatsapp or sms
     * @returns {Object} response.show 是否可以展示下一步弹窗
     */
    async requestSendCode(params, smsType, from = '') {
      // 极验验证
      const res = await geetestChallenge(thirdLoginCodeSend, params, 'send_message')
      if (!res) {
        this.phoneCodeIsSending = false
        this.$evt.$emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.$toast(this.langText.SHEIN_KEY_PWA_16274)
        this.phoneCodeIsSending = false
        this.analyisContinueSend(-1)
        return
      }
      if (status == 'close') {
        this.$evt.$emit('event-loading', false)
        this.phoneCodeIsSending = false
        return
      }
      const data = apiResData || {}
      const { code: sendCode, tips, info } = data
      const { type } = this.newUIStates
      saSend('2-8-101', {
        from,
        scene: type,
        mode: 'phone',
        send_type: smsType,
        sended: this.codeIsSended,
        code: sendCode
      })

      if (sendCode == -1) {
        this.phoneCodeIsSending = false
        this.$evt.$emit('event-loading', false)
        return { show: false }
      }

      this.$evt.$emit('event-loading', false)
      this.analyisContinueSend(sendCode)

      this.phoneCodeIsSending = false
      this.codeFirstRequest = false

      if (sendCode == '404101' && info?.ttl > 0) {
        // 获取剩余倒计时
        this.startDownTime(info.ttl)
      }

      const errorCodes = [
        '404109',
        '404110',
        '404111',
        '404101',
        '404102',
        '404105',
        '404106',
        '404107',
        '100102',
        '0'
      ]
      if (this.commonAbt.WhatsAppSetup.show == 'on' && !errorCodes.includes(sendCode)) {
        this.assignState({
          sendTypeChangePop: true
        })

        sensorsSend('2-8-90', { type: smsType == 'sms' ? '2' : '1' })
        return { show: false }
      }
      this.sendType = smsType
      // 展示验证码输入弹窗
      // 重构ui界面弹窗
      this.setnewUIStates({ showType: 'phone', sendType: smsType })
      if (from == 'continue_btn')
        saSend('2-8-105', { scene: this.newUIStates.type, type: 'phone', acc: this.relatedAcc })

      if (sendCode == 0) {
        this.startDownTime(60)
        this.codeIsSended = true
        this.$toast(this.langText.SHEIN_KEY_PWA_17775)
        return { show: true }
      }

      this.showInputTips(this.__codePopTip, tips || this.langText.SHEIN_KEY_PWA_14899)
      return { show: true }
    },

    // 手机号码登陆
    async requestPhoneLogin(params) {
      this.$evt.$emit('event-loading', true)
      const { daId } = params
      if (
        params.clause_flag == 1 &&
        newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')
      )
        params.clause_agree = 1 // eslint-disable-line
      // 一站多合规mix传地区id
      params.locationId =
        this.newPrivacyAbt.origin == 'mix'
          ? this.defaultLocation?.forceId || this.defaultLocation?.id || ''
          : '' // eslint-disable-line
      // relation为1不查询关联账号
      if (this.relatedAcc.is_relation == '1') params.is_relation = 1 // eslint-disable-line
      params.verification_type = this.sendType == 'whatsApp' ? 1 : 0 // eslint-disable-line
      const { isSwitch } = this.newUIStates
      isSwitch ? params.isSwitchUid = this.preloadData?.uid : ''
      // 极验验证
      const res = await geetestChallenge(phoneLoginSer, params, 'login')
      if (!res) {
        this.$evt.$emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.__codePopTip.show = true
        this.__codePopTip.txt = this.langText.SHEIN_KEY_PWA_16274
        this.$evt.$emit('event-loading', false)
        sensorsSend(daId, { type: this.codeMode == 'code' ? 1 : 2, success: false })
        return
      }
      if (status == 'close') {
        this.$evt.$emit('event-loading', false)
        return
      }
      const response = apiResData || {}
      const { code, msg } = response
      this.$evt.$emit('event-loading', false)

      sensorsSend(daId, {
        code,
        authentication: this.codeMode,
        type: this.codeMode == 'code' ? 1 : 2,
        success: code == 0,
        msg,
        mode: 'phone',
        acc: this.relatedAcc
      })

      // 风控检测
      if (
        this.$refs?.temporaryRef?.detectRiskChalleage(
          response,
          { params, message_type: 'login_confirm', scene: 'login' },
          ({ type, params }) => {
            if (type == 'close') return
            // 双重验证更新密码
            if (type == 'updatePwd') return (this.phonePassword = '')
            this.requestPhoneLogin(params)
          }
        )
      )
        return

      // 登陆成功
      if (code == 0) {
        const { info } = response || {}
        const subscribe_status = info?.member?.subscribe_status || ''
        if(subscribe_status == 1 && !this.setShowPop()){
          const value = {
            show: true,
            cb: () => {
              this.setnewUIStates({ showType: 'continue', isToLogin: false, isSwitch: false })
              this.setSendTypeCache()
              urlRedirection(false, response)
            }
          }
          this.changeDialogs({ key: 'smsSubScribeModal', val: value })
          return
        }
        this.setnewUIStates({ showType: 'continue', isToLogin: false, isSwitch: false })
        this.setSendTypeCache()
        urlRedirection(false, response)
        return
      }
      if(code == 402922){
        this.$evt.$emit('event-loading', false)
        riskVerifyChallenge(response, params, ({ paramsData, type }) => {
          if(type == 'error') {
            this.$evt.$emit('event-loading', false)
            this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.$evt.$emit('event-loading', false)
            return
          }
          this.requestPhoneLogin(paramsData)
        })
        return
      }

      if (code == -410) {
        // 手机号码格式错误
        this.showInputTips(this.__codePopTip, this.langText.SHEIN_KEY_PWA_18690)
        return
      }

      if (code == -411) {
        // 未注册
        if (this.phoneAbt.phoneRegisterPage == 'on' && !this.relatedAcc.global) {
          // 如果处在关联账号填写中不提示引导注册
          this.popNotSignup.show = true
          sensorsSend('2-8-38')
          return
        }
        this.showInputTips(this.__codePopTip, this.langText.SHEIN_KEY_PWA_18725)
        return
      }
      if (code == -414) {
        // 存在邮箱关联账号
        this.handleRelationActions(params, response, 'phone_login')
        return
      }
      if (code == 400544 || code == 400504) {
        this.showInputTips(this.__codePopTip, this.langText.SHEIN_KEY_PWA_18885)
        return
      }

      if (code == '400593') {
        // 未同意新版隐私协议
        this.setNewPrivacyModal({
          from: 'Phone',
          account: params.phone,
          cb: () => {
            this.$evt.$emit('event-loading', true)
            params.clause_agree = 1
            this.requestPhoneLogin(params)
          }
        })
        return
      }

      const errTip = formatNormalErrorMessage(response)
      this.showInputTips(this.__codePopTip, errTip)
    },
    handleRelationActions(parmas, response, from) {
      const { info } = response
      const actionCb = ({ type } = {}) => {
        if (type == 'retain') {
          this.setRelatedAcc({ is_relation: '', isRetain: true })
        } else {
          this.setRelatedAcc({ is_relation: 1 })
        }
        if (type == 'force') {
          // 硬注册
          const singup_params = { ...parmas, force_bind: 0, daId: '2-8-81' }
          if (this.newPrivacyAbt.type == 'yes') singup_params.clause_agree = 1
          this.requestPhoneSignup(singup_params)
        }
      }

      const action_val = {
        list: info?.accounts || [],
        abt: response.abt,
        cb: actionCb,
        is_relation: 1,
        isRetain: true,
        origin: { ...parmas },
      }
      if (this.commonAbt.RelatedAccountRetention == 'on' && this.relatedAcc.isRetain == false) {
        action_val.is_relation = ''
        action_val.isRetain = false
      }
      const loginCb = (res) => {
        this.setShowIndex(false)
        this.assignState({
          showAreadCode: false
        })
        // 关闭新ui界面的验证码弹窗
        this.setnewUIStates({ showType: 'continue'})
        urlRedirection(false, res)
      }
      action_val.from = from
      const vparams = {
        show: true,
        cb: loginCb,
        instance: 'related',
        action: 'relation_account',
        action_val,
        bi: loginPageFrom(),
      }
      SHEIN_LOGIN.show(vparams)
    },
    // 手机号码注册
    async requestPhoneSignup(params) {
      this.$evt.$emit('event-loading', true)
      const { daId } = params
      // 一站多合规mix传地区id
      params.locationId =
        this.newPrivacyAbt.origin == 'mix'
          ? this.defaultLocation?.forceId || this.defaultLocation?.id || ''
          : ''
      // relation为1不查询关联账号
      if (this.relatedAcc.is_relation == '1') params.is_relation = 1
      params.verification_type = this.sendType == 'whatsApp' ? 1 : 0 // eslint-disable-line
      const { isSwitch } = this.newUIStates
      isSwitch ? params.isSwitchUid = this.preloadData?.uid : ''
      // 极验验证
      const res = await geetestChallenge(phoneRegisterSer, params, 'register')
      if (!res) {
        this.$evt.$emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.__codePopTip.show = true
        this.__codePopTip.txt = this.langText.SHEIN_KEY_PWA_16274
        this.$evt.$emit('event-loading', false)
        sensorsSend(daId, { mode: 'phone', result_reason: 'geetest_error', success: false })
        return
      }
      if (status == 'close') {
        this.$evt.$emit('event-loading', false)
        return
      }
      const response = apiResData || {}
      const { code, msg } = response

      sensorsSend(daId, {
        success: code == 0,
        code,
        msg,
        subscribe: this.checkPhoneSubsrib,
        mode: 'phone',
        acc: this.relatedAcc
      })

      this.$evt.$emit('event-loading', false)
      // 注册成功
      if (code == 0) {
        this.setnewUIStates({ showType: 'continue', isToLogin: false, isSwitch: false })
        this.setSendTypeCache()
        if (params.checkboxSubscrib === 1) {
          this.handlePostAddSubscribe(params, '1')
        }
      
        return urlRedirection(false, response)
      }

      if(code == 402922){
        this.$evt.$emit('event-loading', false)
        riskVerifyChallenge(response, params, ({ paramsData, type }) => {
          if(type == 'error') {
            this.$evt.$emit('event-loading', false)
            this.$toast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.$evt.$emit('event-loading', false)
            return
          }
          this.requestPhoneSignup(paramsData)
        })
        return
      }
      
      if (code == -410) {
        this.showInputTips(this.__codePopTip, this.langText.SHEIN_KEY_PWA_18690)
        return
      }

      if (code == -412 || code == '403220') {
        // 已注册
        this.popLogin.show = true
        this.popLogin.params = { ...params }
        sensorsSend('2-8-45')
        return
      }
      if (code == -414) {
        // 存在邮箱关联账号
        this.handleRelationActions(params, response, 'phone_register', this)
        return
      }
      if (code == 402914 && gbCommonInfo.SiteUID == 'mph') {
        this.showInputTips(this.__codePopTip, this.langText.SHEIN_KEY_PWA_22822)
        return
      }
      const errTip = formatNormalErrorMessage(response)
      this.showInputTips(this.__codePopTip, errTip)
    },
    // 设置发送验证码渠道缓存
    setSendTypeCache() {
      if (!['sms', 'whatsApp'].includes(this.sendType)) return
      window.localStorage.setItem('l_p_s_c_used', this.sendType == 'sms' ? '1' : '2')
    },
  },
}
