<template>
  <div class="page-login__com-subscribe">
    <div
      v-if="emailSubSscriptionText && type != 'phone'"
      class="privacy-item"
    >
      <s-checkbox
        v-model="checkboxSubscrib"
        v-expose="{ id: '2-8-67' }"
        class="j-subscrib-checkbox"
        :data-checkbox="'default_not_check'"
        :data-subcribe="SIGNUP_SUBSCRIPTION_SUPPORT ? 'default_subcribe' : (SIGNUP_SUBSCRIPTION_NO_SUPPORT ? 'default_not_subcribe' : '')"
        @change="handleSubscrib"
      >
        <p v-html="emailSubSscriptionText"></p>
      </s-checkbox>
    </div>

    <template v-if="isShowSmsSubscription">
      <div
        class="privacy-item"
      >
        <s-checkbox 
          v-model="checkboxSubscrib"
          v-expose="{ id: '2-8-54'}"
          name="subscrib"
          class="j-subscrib-checkbox"
          :data-checkbox="smsIsDefaultSelected ? 'default_check' : 'default_not_check'"
          @change="handleSubscrib"
        >
          <template v-if="isPWUS">
            <p 
              v-if="newUIStates.showType == 'phone'"
            >
              {{ langText.SHEIN_KEY_PWA_30802 }}
            </p>
            <p
              v-else
              v-html="smsSubLongText"
            ></p>
          </template>
          <p 
            v-else
          >
            {{ langText.SHEIN_KEY_PWA_14631 }}
          </p>
        </s-checkbox>
        <p 
          v-if="isPWUS && newUIStates.showType == 'phone'"
          class="privacy-tips"
          :class="{
            'show-tips': checkboxSubscrib
          }"
          v-html="smsSubLongText"
        >
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { template } from '@shein/common-function'
import analysisIndex from '../../analysis/index'
const { sensorsSend } = analysisIndex

const { SiteUID, langPath, SMS_SUBSCIRIPE_DEFAULT_SELECTED = '', SMS_SUBSCIRIPE_DEFAULT_NO_SELECTED = '' } = gbCommonInfo

export default {
  name: 'LoginSubScrib',
  props: {
    type: { type: String, default: '' },
    // defaultSubscrib: { type: Boolean, default: false },
  },
  data: () => ({
    SiteUID,
    isPWUS: ['pwus'].includes(SiteUID),
    defaultSubscrib: false,
    checkboxSubscrib: false,
    SIGNUP_SUBSCRIPTION_SUPPORT: gbCommonInfo.SIGNUP_SUBSCRIPTION_SUPPORT,
    SIGNUP_SUBSCRIPTION_NO_SUPPORT: gbCommonInfo.SIGNUP_SUBSCRIPTION_NO_SUPPORT,
  }),
  computed: {
    ...mapState('login', ['langText', 'preloadData', 'newUIStates', 'commonAbt', 'phoneBind', 'smsSubScribeCheckBox', 'relatedAcc']),
    // 手机号订阅默认是否勾选
    smsIsDefaultSelected () {
      // 手机号默认订阅勾选
      const default_selected = SMS_SUBSCIRIPE_DEFAULT_SELECTED.split?.(',')
      // 如果阿波罗配置手机号码默认勾选，展示手机号订阅
      if (default_selected.includes(SiteUID)) return true
      return false
    },
    isShowSmsSubscription () {
      if ((this.SiteUID == 'mkr') && (this.newUIStates.type == 'signup')) return false
      if (this.newUIStates.type == 'login' && !this.phoneBind.show) return false
      if(this.smsSubScribeCheckBox) return false
      if (this.type != 'phone') return false
      if(this.isPWUS){
        if(!this.isFromSignup && this.phoneBind.show) return false
        if(!this.phoneBind.show && !this.commonAbt.USRegisterSMS.show)return false
      }
      
      // 默认勾选
      if (this.smsIsDefaultSelected) return true

      // 手机号默认订阅不勾选
      const default_no_selected = SMS_SUBSCIRIPE_DEFAULT_NO_SELECTED.split(',')

      // 如果阿波罗配置手机号码默认勾选，展示手机号订阅
      if (default_no_selected.includes(SiteUID)) return true

      return false
    },
    isShowSubscription () {
      return this.SIGNUP_SUBSCRIPTION_SUPPORT || this.SIGNUP_SUBSCRIPTION_NO_SUPPORT
    },
    emailSubSscriptionText () {
      if ((this.SiteUID == 'mkr') && (this.newUIStates.type == 'signup')) return ''
      if(this.relatedAcc?.show) return ''
      const subscribe_status = this.newUIStates.subscribe_status || ''
      if(this.newUIStates.type == 'login' && subscribe_status != 1) return ''
      const isSupport = this.SIGNUP_SUBSCRIPTION_SUPPORT || this.SIGNUP_SUBSCRIPTION_NO_SUPPORT
      if (!isSupport) return ''
      // 默认订阅
      if (this.SIGNUP_SUBSCRIPTION_SUPPORT) {
        if(SiteUID == 'rwmus' && this.newUIStates.type == 'signup') {
          return this.langText?.SHEIN_KEY_PWA_35736 || ''
        }
        return subscribe_status == 1 ? this.langText.SHEIN_KEY_PWA_30941 : this.langText.SHEIN_KEY_PWA_14599
      }
      // 默认不订阅
      if (this.SIGNUP_SUBSCRIPTION_NO_SUPPORT) {
        // kr
        if (this.SiteUID == 'mkr') return template(
          `<a href="${langPath}/product/article/2615" target="_blank">${this.langText.SHEIN_KEY_PWA_31877}</a>`,
          this.langText.SHEIN_KEY_PWA_31876
        )
        // de
        if (['rwmde', 'pwde'].includes(this.SiteUID)) return this.langText.SHEIN_KEY_PWA_18873
       
        return this.langText.SHEIN_KEY_PWA_30939
      }
      return ''
    },
    privacyHref () {
      if (WEB_CLIENT == 'shein') {
        return `${langPath}/Privacy-Security-Policy-a-282.html`
      }
      return `${langPath}/Privacy-Security-Policy-a-488.html`
    },
    smsSubLongText(){
      const aTag = `<a href="${langPath}/Terms-and-Conditions-a-399.html" target="_blank">${this.langText.SHEIN_KEY_PWA_24589}</a>`
      const bTag = `<a href="${this.privacyHref}" target="_blank">${this.langText.SHEIN_KEY_PWA_24590}</a>`
      return template(aTag, bTag, this.langText.SHEIN_KEY_PWA_30911)
    },
    isFromSignup(){
      return this.newUIStates.type === 'signup'
    },
  },
  mounted () {
    if (this.type == 'phone') { // 手机默认是否勾选
      // 手机号默认订阅勾选
      const default_selected = SMS_SUBSCIRIPE_DEFAULT_SELECTED.split(',')
      this.checkboxSubscrib = default_selected.includes(SiteUID)
    } 
    
    if (this.checkboxSubscrib) this.$emit('subscrip-change', true, 'subscrib', this.type)
  },
  methods: {
    handleSubscrib () {
      let daid = ''
      let checkbox = ''
      let subcribe = ''
      if (this.type == 'phone') {
        daid = '2-8-53'
        checkbox = this.smsIsDefaultSelected ? 'default_check' : 'default_not_check'
      } else {
        daid = '2-8-68'
        checkbox = 'default_not_check'
        subcribe = this.SIGNUP_SUBSCRIPTION_SUPPORT ? 'default_subcribe' : (this.SIGNUP_SUBSCRIPTION_NO_SUPPORT ? 'default_not_subcribe' : '')
      }
      sensorsSend(daid, { 
        subcribe,
        checkbox,
        selected: this.checkboxSubscrib,
      })
      this.$emit('subscrip-change', this.checkboxSubscrib, 'subscrib', this.type)
    },
    handleWhatsappCick (e) {
      if (e.target.nodeName == 'A') {
        const href = e.target.getAttribute('href')
        window.open(href)
      }
    }
  }
}
</script>

<style lang="less">
.page-login__com-subscribe {
    margin-top: 11px;
    a {
        color: @sui_color_link;
        text-decoration: none;
    }
    .privacy-item{
      font-family: "SF UI Display";
      flex-wrap: wrap;
      overflow: hidden;
      .S-checkbox__label {
        color: #767676;
        line-height: 1.1;
        .font-dpr(24px);
        .padding-l(12px);
      }
      .S-checkbox__input{
        align-self: flex-start;
      }
    }
    .privacy-tips{
      padding-left: 32px;
      margin-right: 8px;
      color: #767676;
      line-height: 1.1;
      font-size: 10px;
      height: 0;
      transition: height 0.4s;
      &.show-tips{
        height: auto;
      }
    }
}
</style>
